import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
// import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { dayjs } from "../../Helpers/dayjs";
import { DataTable } from "../../components/DataTable/DataTable";
import { Button, Card, CardHeader, Divider } from "@mui/material";
import { DataTablePagination } from "../../components/DataTable/DataTablePagination";
import { DataTableFilters } from "../../components/DataTable/DataTableFilters";
import { getInitials } from "../../Helpers/GetInitialsFromName";
import { useEffect } from "react";
import settings from "../../settings/api";
import { Users as UsersIcon } from "@phosphor-icons/react/dist/ssr/Users";
import { useTranslation } from "react-i18next";
import fetchData from "../../Helpers/apiCalls";
import * as P from "@phosphor-icons/react";

export function UsersList() {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 25,
    });

    const columns = [
        {
            formatter: (row) => (
                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                    <Avatar
                        // sx={{ width: 32, height: 32 }}
                        src={row.avatar}
                    >
                        {getInitials(row.name)}
                    </Avatar>{" "}
                    <div>
                        <Link
                            color="inherit"
                            component={RouterLink}
                            to={row.pageUrl}
                            sx={{ whiteSpace: "nowrap" }}
                            variant="subtitle2"
                        >
                            {row.firstName} {row.lastName}
                        </Link>
                        <Typography color="text.secondary" variant="body2">
                            {row.login}
                        </Typography>
                    </div>
                </Stack>
            ),
            name: t("base.userView_site_users_column_name", "Name"),
            width: "200px",
        },
        {
            formatter: (row) => {
                const PIcon = P[row.status.icon];
                return (
                    <Chip
                        label={row.status.label}
                        sx={{ bgcolor: row.status.color, color: "#FFF", borderColor: row.status.color }}
                        size="small"
                        variant="outlined"
                        icon={<PIcon color={"#FFF"} weight="fill" />}
                    />
                );
            },
            name: t("base.userView_site_users_column_status", "Status"),
            width: "100px",
        },
        {
            formatter(row) {
                const date = dayjs(row.lastSessionUtc).format("MMM D, YYYY h:mm A");
                return date !== "Invalid Date" ? date : "N/A";
            },
            name: t("base.userView_site_users_column_signon", "Last Signon"),
            width: "200px",
        },
        {
            formatter: (row) => {
                const mapping = {
                    USER: {
                        label: "User",
                        icon: <UserIcon color="#15b79f" weight="fill" />,
                    },
                    MANAGER: { label: "Manager", icon: <UserIcon color="#fb9c0c" weight="fill" /> },
                    ADMIN: {
                        label: "Admin",
                        icon: <UserIcon color="#f04438" weight="fill" />,
                    },
                };
                const { label, icon } = mapping[row.userRoleCode] ?? { label: "Unknown", icon: null };
                // console.log(label, icon);

                return <Chip icon={icon} label={label} size="small" variant="outlined" />;
            },
            name: t("base.userView_site_users_column_role", "Role"),
            width: "100px",
            align: "center",
        },
        {
            formatter: (row) => {
                return (
                    <IconButton component={RouterLink} to={row.pageUrl}>
                        <PencilSimpleIcon />
                    </IconButton>
                );
            },
            name: "View",
            hideName: true,
            width: "100px",
            align: "center",
        },
    ];

    useEffect(() => {
        fetchUsers(location.search);

        return () => {};
    }, [location.search]);

    const fetchUsers = async (filters = "") => {
        
        const url = !!filters
            ? settings.api().users + filters + `&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`
            : settings.api().users + `?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`;
        console.log(url);
        const result = await fetchData(url, "GET");
        if (result.success) {
            setUsers(result.data);
        } else {
            console.error("Errors: " + result.errors.map((e) => e.errorMsg + ", "));
        }
    };

    return (
        <Box
            sx={{
                maxWidth: "var(--Content-maxWidth)",
                m: "var(--Content-margin)",
                p: "var(--Content-padding)",
                width: "var(--Content-width)",
            }}
        >
            <Stack spacing={1}>
                <Card>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ alignItems: "flex-start" }}>
                        <Box sx={{ flex: "1 1 auto" }}>
                            <CardHeader
                                avatar={
                                    <Avatar>
                                        <UsersIcon fontSize="large" />
                                    </Avatar>
                                }
                                title={
                                    <Typography variant="h6">{t("base.userView_site_title_users", "Users")}</Typography>
                                }
                            />
                        </Box>
                        {!!users?.links?.find((l) => l.rel === "add") && (
                            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                                <Button
                                    startIcon={<PlusIcon />}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/portal/site/add-user")}
                                >
                                    {t("base.button_add", "Add")}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                    {users?.filteringOptions && (
                        <DataTableFilters
                            sorts={users?.sortingOptions}
                            filters={users?.filteringOptions}
                            initialSortDir={users?.sortingOptions[0].field}
                        />
                    )}
                    <Divider />
                    <Box sx={{ overflowX: "auto" }}>
                        <DataTable columns={columns} rows={users?.items} />
                    </Box>
                    <Divider />
                    {users?.totalItems > 0 && (
                        <DataTablePagination
                            count={users?.totalItems}
                            pageSize={users?.pageSize}
                            pageNumber={users?.pageNumber}
                        />
                    )}
                </Card>
            </Stack>
        </Box>
    );
}
