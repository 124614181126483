import React, { useEffect, useState } from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import typography from "./theme/typography";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes/AppRouter";
import { getSubdomain } from "./Helpers/getSubdomain";
import settings from "./settings/api";
import { useValidateDomainTenant } from "./Helpers/hooks/useValidateDomainTenant ";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingSpinner from "./components/Spinner";
import fetchData from "./Helpers/apiCalls";

export const nevada = {
    50: "#fbfcfe",
    100: "#f0f4f8",
    200: "#dde7ee",
    300: "#cdd7e1",
    400: "#9fa6ad",
    500: "#636b74",
    600: "#555e68",
    700: "#32383e",
    800: "#202427",
    900: "#121517",
    950: "#090a0b",
};
export const kepple = {
    50: "#f0fdfa",
    100: "#ccfbef",
    200: "#9af5e1",
    300: "#5fe9ce",
    400: "#2ed3b8",
    500: "#15b79f",
    600: "#0e9382",
    700: "#107569",
    800: "#115e56",
    900: "#134e48",
    950: "#042f2c",
};

function App() {
    const element = useRoutes(routes);
    const subdomain = getSubdomain();
    const [theme, setTheme] = useState(null);

    useValidateDomainTenant();

    const fetchThemeData = async () => {        
        try {
            const result = await fetchData(settings.api().portalInfo);
            if (result.success) {
                return result.data.theme;
            }
        } catch (error) {
            console.error("Failed to fetch theme data:", error);
            return null; // Return null or a default theme object if there's an error
        }
    };

    useEffect(() => {
        const loadTheme = async () => {
            const themeData = await fetchThemeData();
            const isAdmin = localStorage.getItem("menuType") !== "user";
           
            const defaultTheme = createTheme({
                palette: {
                    primary: {
                        contrastText: "#FFFFFF",
                        main: "#1976d2",
                    },
                    secondary: {
                        contrastText: "#1976d2",
                        main: "#FFFFFF",
                    },
                    icon: "#1976d2",
                    text: {
                        primary: "rgba(0, 0, 0, 0.87)",
                        secondary: "#727982",
                    },
                },
                typography: {
                    typography,
                },
                components: {
                    MuiDrawer: {
                        styleOverrides: {
                            paper: {
                                marginTop: "75px", // Set margin-top for the drawer
                            },
                        },
                    },
                },
            });

            let customTheme;

            if (themeData) {
                customTheme = createTheme({
                    palette: {
                        primary: {
                            main: `#${themeData.headerColour}`,
                            contrastText: "#FFFFFF",
                        },
                        secondary: {
                            ...nevada,
                            light: nevada[600],
                            main: isAdmin ? "#2b2b2b" : nevada[700],
                            dark: nevada[800],
                            contrastText: isAdmin ? "#2b2b2b" : "#fff",
                        },
                        success: {
                            ...kepple,
                            light: kepple[400],
                            main: kepple[500],
                            dark: kepple[600],
                            contrastText: "#fff",
                        },
                        text: {
                            primary: isAdmin ? "#2b2b2b" : "#212636",
                            secondary: "#727982",
                            active: isAdmin ? "#FFFFFF" : "#212636",
                            disabled: isAdmin ? "#6D7680" : "#8a94a6",
                        },
                    },
                    icon: `#${themeData.headerColour}`,
                    typography: {
                        typography,
                    },
                    components: {
                        MuiDrawer: {
                            styleOverrides: {
                                paper: {
                                    marginTop: "75px", // Set margin-top for the drawer
                                    backgroundColor: isAdmin ? "#d6d6d6" : null,
                                    // color: isAdmin ? "#B0B8C1" : null,
                                },
                            },
                        },
                        MuiCard: {
                            styleOverrides: {
                                root: {
                                    // backgroundColor: isAdmin ? "#d6d6d6" : null,
                                    // backgroundColor: isAdmin ? "#fff" : null,
                                    // color: isAdmin ? "#2b2b2b" : null,
                                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)", // Custom darker shadow
                                },
                            },
                        },
                        // MuiTab: {
                        //     styleOverrides: {
                        //         root: {
                        //             backgroundColor: isAdmin ? "#fff" : null,
                        //         },
                        //     },
                        // },
                        // MuiPaper: {
                        //     styleOverrides: {
                        //         root: {
                        //             backgroundColor: isAdmin ? "#d6d6d6" : null,
                        //             // color: isAdmin ? "#2b2b2b" : null,
                        //             // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)", // Custom darker shadow
                        //         },
                        //     },
                        // },
                    },
                });
            }
            
            setTheme(themeData ? customTheme : defaultTheme);
        };

        loadTheme();
    }, [subdomain, localStorage.getItem("menuType")]);

    if (!theme) {
        // Optionally, show a loading state while the theme is being fetched
        return <LoadingSpinner />;
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>{element}</LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
