import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { ShieldWarning as ShieldWarningIcon } from "@phosphor-icons/react/dist/ssr/ShieldWarning";
import { LockKeyOpen as LockKeyOpenIcon } from "@phosphor-icons/react/dist/ssr/LockKeyOpen";
import { Password as PasswordIcon } from "@phosphor-icons/react/dist/ssr/Password";
import BootstrapInput from "../../../components/BootstrapTextField";
import { useParams } from "react-router-dom";
import BootstrapSelect from "../../../components/BootstrapSelect";
import AccountLayout from "../Account";

export default function UserSecurity() {
    const { id } = useParams();
    const [permissions, setPermissions] = useState({
        role: "Manager",
        status: "Active",
    });

    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        userId: 0,
    });
    const [showError, setShowError] = useState(false);

    const handlePermissonChange = (e) => {
        const { name, value } = e.target;
        setPermissions({ ...permissions, [name]: value });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setUpdatePassword({
            ...updatePassword,
            [name]: value,
        });
    };

    const handleUpdatePasswordChange = () => {
        if (
            updatePassword.currentPassword === "" ||
            updatePassword.newPassword === "" ||
            updatePassword.confirmPassword === ""
        ) {
            setShowError(true);
            return;
        }
        if (updatePassword.newPassword !== updatePassword.confirmPassword) {
            setShowError(true);
            return;
        }
        // fetchData(settings.api().users + `/${id}/change-password`, "PUT", newPasswords)
    };

    const navItems = [
        {
            key: "personal",
            title: "Personal",
            items: [
                { key: "account", title: "Account", href: `/portal/site/users/${id}`, icon: "user-circle" },
                { key: "security", title: "Security", href: `/portal/site/users/${id}/security`, icon: "lock-key" },
            ],
        },
    ];

    return (
        <AccountLayout navItems={navItems} profile={{ name: "", secondary: "" }}>
            <Stack direction="column" spacing={4}>
                <div>
                    <Typography variant="h4">Manage User</Typography>
                </div>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <LockKeyOpenIcon fontSize="small" />
                            </Avatar>
                        }
                        title="Access"
                    />
                    <CardContent>
                        <Typography variant="subtitle1">Permissions</Typography>
                        <Divider />
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                display: "grid",
                                gridTemplateColumns: { sm: "1fr 1fr" },
                                gap: 2,
                                py: 2,
                            }}
                        >
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    Role
                                </InputLabel>
                                <BootstrapSelect
                                    name={"role"}
                                    value={permissions?.role}
                                    onChange={handlePermissonChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Role--</em>
                                    </MenuItem>
                                    <MenuItem value={"Manager"}>Manager</MenuItem>
                                    <MenuItem value={"Admin"}>Admin</MenuItem>

                                    {/* {userInfo?.locale?.values?.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))} */}
                                </BootstrapSelect>
                            </FormControl>
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    Status
                                </InputLabel>
                                <BootstrapSelect
                                    name={"status"}
                                    value={permissions.status}
                                    onChange={handlePermissonChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Status--</em>
                                    </MenuItem>
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"Disable"}>Disable</MenuItem>
                                    {/* {timezones.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))} */}
                                </BootstrapSelect>
                            </FormControl>
                        </Box>
                        <Button color="error" variant="outlined">
                            Save
                        </Button>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <PasswordIcon fontSize="large" />
                            </Avatar>
                        }
                        title={<Typography variant="h6">User Password</Typography>}
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Typography variant="subtitle1">Change Password</Typography>
                        <Divider />
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                display: "grid",
                                gridTemplateColumns: { sm: "1fr 1fr" },
                                gap: 2,
                                py: 2,
                            }}
                        >
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>New Password</strong>
                                </InputLabel>
                                <BootstrapInput
                                    type="password"
                                    name={"newPassword"}
                                    onChange={handlePasswordChange}
                                    value={updatePassword.newPassword}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Confirm Password</strong>
                                </InputLabel>
                                <BootstrapInput
                                    type="password"
                                    name={"confirmPassword"}
                                    onChange={handlePasswordChange}
                                    value={updatePassword.confirmPassword}
                                />
                            </FormControl>
                        </Box>
                        <div style={{ marginTop: "0px" }}>
                            <Button
                                variant="outlined"
                                color="error"
                                style={{ marginRight: "10px" }}
                                onClick={handleUpdatePasswordChange}
                            >
                                Update Password
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <ShieldWarningIcon fontSize="small" />
                            </Avatar>
                        }
                        title="Security"
                    />
                    <CardContent>
                        <Stack spacing={1}>
                            <div>
                                <Button color="error" variant="contained">
                                    Delete account
                                </Button>
                            </div>
                            <Typography color="textSecondary" variant="body2">
                                A deleted customer cannot be restored. All data will be permanently removed.
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </AccountLayout>
    );
}
