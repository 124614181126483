import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import settings from "../../../settings/api";
import fetchData from "../../../Helpers/apiCalls";
import { CalendarPlus as CalendarPlusIcon } from "@phosphor-icons/react/dist/ssr/CalendarPlus";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import { SettingsDrawer } from "../../../components/TranslationDrawer/TranslationDrawer";
import AddEditTPOScheduleForm from "../../../components/AddEditTPOScheduleForm";

export default function AddEditTPOSchedule({ mode }) {
    const navigate = useNavigate();
    const [organizations, setOrganizations] = useState([]);
    const [products, setProducts] = useState([]);
    const [types, setTypes] = useState([]);
    const [createScheduleInfo, setCreateScheduleInfo] = useState({
        title: "",
        description: "",
        startDate: null,
        endDate: null,
        organizationId: "",
        productId: "",
        scheduleTypeId: "",
    });
    const [dateError, setDateError] = useState({
        startDateError: false,
        endDateError: false,
    });
    const [openTranslationDrawer, setOpenTranslationDrawer] = useState(false);
    const [translationField, setTranslationField] = useState({});

    // const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        const fetchProductsNTypes = async () => {
            const organizationResult = await fetchData(settings.api().tpoScheduleOrganizations, "GET");

            if (organizationResult.success) {
                setOrganizations(organizationResult.data);
            }

            const productResult = await fetchData(settings.api().tpoScheduleProducts, "GET");
            if (productResult.success) {
                setProducts(productResult.data);
            }

            const seasonsResult = await fetchData(settings.api().tpoScheduleTypes, "GET");
            if (seasonsResult.success) {
                setTypes(seasonsResult.data);
            }
        };

        fetchProductsNTypes();

        return () => {};
    }, []);

    const handleCreateScheduleInfoChange = (e) => {
        // console.log(e);

        const { name, value } = e.target;
        setCreateScheduleInfo({
            ...createScheduleInfo,
            [name]: value,
        });
    };
    const handleCreateScheduleDateChange = (date, name) => {
        setCreateScheduleInfo({
            ...createScheduleInfo,
            [name]: date?.toDate(),
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(createScheduleInfo.startDate === null);

        let errors = dateError;

        if (createScheduleInfo.startDate === null) {
            errors = {
                ...errors,
                startDateError: true,
            };
        } else {
            errors = {
                ...errors,
                startDateError: false,
            };
        }

        if (createScheduleInfo.endDate === null) {
            errors = {
                ...errors,
                endDateError: true,
            };
        } else {
            errors = {
                ...errors,
                endDateError: false,
            };
        }
        setDateError(errors);
        const CreateSchedule = async () => {
            const result = await fetchData(settings.api().tpoSchedules, "POST", createScheduleInfo);
            if (result.success) {
                navigate("/admin/global/tpo/schedules");
            }
            console.log(result);
        };
        if (createScheduleInfo.startDate !== null && createScheduleInfo.endDate !== null) {
            CreateSchedule();
        }
    };

    return (
        <div>
            <Stack>
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <CalendarPlusIcon fontSize="x-large" />
                                </Avatar>
                            }
                            title={mode === "create" ? "Add Schedule Info" : "Edit Schedule Info"}
                        />
                        <CardContent>
                            <AddEditTPOScheduleForm mode={"create"} />
                        </CardContent>
                    </Card>
                </form>
            </Stack>
            <SettingsDrawer
                onClose={() => {
                    setOpenTranslationDrawer(false);
                }}
                open={openTranslationDrawer}
                field={translationField}
                // onReset={handleReset}
                // onUpdate={handleUpdate}
                // values={settings}
            />
        </div>
    );
}
