// import styled from "@emotion/styled";
// import { InputBase } from "@mui/material";

// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//     "label + &": {
//         marginTop: theme.spacing(3),
//     },
//     "& .MuiInputBase-input": {
//         borderRadius: 4,
//         position: "relative",
//         border: "1px solid",
//         borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
//         fontSize: 16,
//         padding: "10px 12px",
//         transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
//         "&:focus": {
//             borderColor: theme.palette.primary.main,
//         },
//     },
// }));

// export default BootstrapInput;

import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const BootstrapInput = styled(TextField)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiTextField-input": {
        borderRadius: 4,
        position: "relative",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default BootstrapInput;
