import React, { useEffect, useState } from "react";
import { Button, CardActions, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomCard, CustomContainer } from "./AccountActivation";
import CopyRight from "../../components/CopyRight";
import fetchData from "../../Helpers/apiCalls";
import settings from "../../settings/api";

export default function UserAuthEmailVerification() {
    const location = useLocation();
    const navigate = useNavigate();
    const { title, description } = location.state || {};
    const [logo] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme?.logoSmallUri);
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        verifyEmail();

        return () => {};
    }, []);

    const verifyEmail = async () => {
        const result = await fetchData(settings.api().verifyEmail + `${location.search}`, "GET");
        console.log(result);

        if (result.success) {
            console.log(result);
            setValidated(true);
        }
        // navigate to notification page with result
    };

    return (
        <CustomContainer>
            <CustomCard>
                <img src={logo} alt="Logo" style={{ width: "80px", display: "block", margin: "0 auto" }} />

                <CardContent>
                    <Typography variant="h5" sx={{ width: "100%", textAlign: "center" }} gutterBottom>
                        {validated ? "Email has been verified." : "Email being verified"}
                    </Typography>
                    <Typography variant="body1" sx={{ width: "100%", textAlign: "center" }}>
                        {validated ? "Please login" : "loading..."}
                    </Typography>
                    {/* {!!title && (
                            <Typography variant="h5" sx={{ width: "100%", textAlign: "center" }} gutterBottom>
                                {title}
                            </Typography>
                        )}
                        {!!description && (
                            <Typography variant="body1" sx={{ width: "100%", textAlign: "center" }}>
                                {description}
                            </Typography>
                        )} */}
                </CardContent>

                {validated && (
                    <CardActions sx={{ justifyContent: "center" }}>
                        <Button onClick={() => navigate("/login")} variant="contained">
                            Login
                        </Button>
                    </CardActions>
                )}
            </CustomCard>
            <CopyRight />
        </CustomContainer>
    );
}
