import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import settings from "../../settings/api";
import { Link, useNavigate } from "react-router-dom";
import generateRandomPassword from "../../Helpers/RandomPasswordGenerator";
import fetchData, { fetchPublicData } from "../../Helpers/apiCalls";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WarningIcon from "@mui/icons-material/Warning";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        border: "1px solid rgb(133, 133, 133)",
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
        "&:disabled": { borderColor: "#E0E3E7" },
    },
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const AddUser = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        login: "",
        email: "",
        sendEmail: true,
        missingEmail: false,
        password: "",
        code: "",
        phone: "",

        userRoleId: 0,
        userDepartmentId: 0,
        userPositionId: 0,
        timeZoneId: 0,
        localeId: 0,
    });
    // const [organizationId] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo")).id);
    // const [organizationInfo, setOrganizationInfo] = useState({});
    const [locales, setLocales] = useState([]);
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [timezones, setTimezones] = useState([]);
    const [roles, setRoles] = useState([]);
    const [positions, setPositions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [validatePassword, setValidatePassword] = useState("");

    useEffect(() => {
        // setUserInfo({ ...userInfo, organizationId: organizationId });
        // setOrganizationInfo({ ...JSON.parse(localStorage.getItem("userOrganizationInfo")) });
        setLocales(JSON.parse(localStorage.getItem("userOrganizationInfo")).locales);

        fetchUserRoles();
        fetchUserPositions();
        fetchUserDepartments();
        fetchTimeZones();
        return () => {};
    }, []);

    const fetchTimeZones = async () => {
        const result = await fetchData(settings.api().timezones, "GET");
        if (result.success) {
            setTimezones(result.data);
        }
    };
    const fetchUserRoles = async () => {
        const result = await fetchData(settings.api().userRoles, "GET");
        if (result.success) {
            setRoles(result.data);
        }
    };
    const fetchUserPositions = async () => {
        const result = await fetchData(settings.api().userPositions, "GET");
        if (result.success) {
            setPositions(result.data);
        }
    };
    const fetchUserDepartments = async () => {
        const result = await fetchData(settings.api().userDepartments, "GET");
        if (result.success) {
            setDepartments(result.data);
        }
    };

    const handleNewGroupInfoChange = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    };

    const createNewUser = async () => {
        setErrors({});
        const result = await fetchData(settings.api().users, "POST", userInfo);
        // console.log(JSON.stringify(userInfo), result);

        if (result.success) {
            navigate(`/portal/site`);
        } else {
            setShowError(true);
            setErrors(result);
        }
    };

    const createPassword = () => {
        const pass = generateRandomPassword();
        setUserInfo({ ...userInfo, password: pass });
        validatePasswordWithApi(pass);
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setUserInfo({ ...userInfo, password: value });
        validatePasswordWithApi(value);
    };

    const validatePasswordWithApi = async (value) => {
        const result = await fetchPublicData(settings.api().validatePassword, "POST", value);
        if (result.success) {
            setValidatePassword("");
        } else {
            setValidatePassword(result.message);
        }
    };

    return (
        <div>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <PersonAddIcon />
                        </Avatar>
                    }
                    title={
                        <Typography variant="h6" gutterBottom>
                            Create New User
                        </Typography>
                    }
                />
                <CardContent>
                    <Typography variant="subtitle1">Create User Information</Typography>
                    <Divider />
                    <Box>
                        {/* <Typography variant="body2" sx={{mt: 1}}>Using email is strongly recommended.</Typography> */}
                        {!userInfo.missingEmail ? (
                            <Box
                                component="form"
                                noValidate
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: { sm: "1fr 1fr" },
                                    gap: 2,
                                    py: 2,
                                }}
                            >
                                <Box>
                                    <FormControl variant="standard" fullWidth required={!userInfo.missingEmail}>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            <strong>Email</strong> (Using email is strongly recommended.)
                                        </InputLabel>
                                        <BootstrapInput
                                            name={"email"}
                                            type="email"
                                            onChange={(e) => handleNewGroupInfoChange(e)}
                                            value={userInfo.email}
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Email account details to user"
                                        checked={userInfo?.sendEmail}
                                        onChange={() =>
                                            setUserInfo({
                                                ...userInfo,
                                                sendEmail: !userInfo.sendEmail,
                                            })
                                        }
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="I don't have an email for this user"
                                        checked={userInfo?.missingEmail}
                                        onChange={() =>
                                            setUserInfo({
                                                ...userInfo,
                                                missingEmail: !userInfo.missingEmail,
                                                email: "",
                                                sendEmail: false,
                                            })
                                        }
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                component="form"
                                noValidate
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: { sm: "1fr 1fr" },
                                    gap: 2,
                                    py: 2,
                                }}
                            >
                                <FormControl variant="standard" fullWidth required={userInfo.missingEmail}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Username</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"login"}
                                        value={userInfo.login}
                                        onChange={handleNewGroupInfoChange}
                                    />
                                </FormControl>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="I have an email for this user"
                                        checked={!userInfo?.missingEmail}
                                        onChange={() =>
                                            setUserInfo({
                                                ...userInfo,
                                                missingEmail: false,
                                                sendEmail: true,
                                                login: "",
                                            })
                                        }
                                    />
                                </Box>
                            </Box>
                        )}
                        <Divider />
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>First Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"firstName"}
                                value={userInfo.firstName}
                                onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Last Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"lastName"}
                                value={userInfo.lastName}
                                onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Phone Number</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"phone"}
                                type="tel"
                                value={userInfo.phone}
                                onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Code (Employee Identifier)
                            </InputLabel>
                            <BootstrapInput
                                name={"code"}
                                value={userInfo.code}
                                onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"password"}
                                onChange={(e) => handlePasswordChange(e)}
                                value={userInfo.password}
                            />
                        </FormControl>
                        <Button
                            variant="outlined"
                            onClick={createPassword}
                            style={{ height: "43px", marginTop: "25px" }}
                        >
                            Generate Password
                        </Button>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>User Role</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.userRoleId}
                                name={"userRoleId"}
                                onChange={handleNewGroupInfoChange}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select User Role--</em>
                                </MenuItem>
                                {roles.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        {r.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>User Department</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.userDepartmentId}
                                name={"userDepartmentId"}
                                onChange={handleNewGroupInfoChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300, // Set the maxHeight of the Paper
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select User Department--</em>
                                </MenuItem>
                                {departments.map((d) => (
                                    <MenuItem key={d.id} value={d.id}>
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>User Position</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.userPositionId}
                                name={"userPositionId"}
                                onChange={handleNewGroupInfoChange}
                                
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300, // Set the maxHeight of the Paper
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select User Position--</em>
                                </MenuItem>
                                {positions.map((p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Language</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.localeId}
                                name={"localeId"}
                                onChange={handleNewGroupInfoChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300, // Set the maxHeight of the Paper
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Language--</em>
                                </MenuItem>
                                {locales.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Time Zone</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.timeZoneId}
                                name={"timeZoneId"}
                                onChange={handleNewGroupInfoChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300, // Set the maxHeight of the Paper
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Time Zone--</em>
                                </MenuItem>
                                {timezones.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                    </Box>
                    {!errors.success && (
                        <>
                            {errors?.errors?.map((e, i) => (
                                <Stack alignItems="center" direction="row" gap={1} key={i}>
                                    <WarningIcon color="error" />
                                    <Typography variant="body1" color="error">
                                        {e.errorMsg}
                                    </Typography>
                                </Stack>
                            ))}
                        </>
                    )}
                    {!!validatePassword && (
                        <Stack alignItems="center" direction="row" gap={1}>
                            <WarningIcon color="error" />
                            <Typography variant="body1" color="error">
                                {validatePassword}
                            </Typography>
                        </Stack>
                    )}
                    <div style={{ marginTop: "10px" }}>
                        <Button variant="contained" style={{ marginRight: "10px" }} onClick={createNewUser}>
                            Create
                        </Button>
                        <Button variant="outlined" component={Link} to={`/portal/site`}>
                            Cancel
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <Dialog
                open={showError}
                onClose={() => setShowError(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Oops! Please fill in all required (*) fields.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowError(false)}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddUser;
