import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardHeader, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import fetchData from "../../../Helpers/apiCalls";
import settings from "../../../settings/api";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { useNavigate } from "react-router-dom";
import ActivityLogDisplay from "../../../components/ActivityLogDisplay";
import { useTranslation } from "react-i18next";
import { formatdatetime } from "../../../Helpers/DateFormatter";

// const columns = [
//     { field: "logId", name: "Log ID", width: "100px" },
//     {
//         formatter: (row) => (
//             <div>
//                 <Typography sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
//                     {row.userFirst} {row.userLast}
//                 </Typography>
//                 <Typography color="text.secondary" variant="body2">
//                     {row.userId}
//                 </Typography>
//             </div>
//         ),
//         name: "User",
//         width: "120px",
//     },
//     { field: "details", name: "Details", width: "250px" },
//     { field: "changes", name: "Changes", width: "150px" },
//     { field: "createdUtc", name: "Created", width: "100px" },
// ];

const Activity = () => {
    const { t } = useTranslation();
    const [activities, setActivities] = useState([]);
    const [openViewActivity, setOpenViewActivity] = useState(false);
    const [activityId, setActivityId] = useState("");

    const columns = [
        { field: "logId", name: "Log ID", width: "180px" },
        {
            formatter: (row) => (
                <div
                    style={{
                        display: "block",
                        // width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {/* <Tooltip title={row.userId} placement="top"></Tooltip> */}
                    <Typography noWrap variant="body2">
                        {row.userFirst} {row.userLast}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {row.userId}
                    </Typography>
                </div>
            ),
            name: "User",
            width: "120px",
        },
        { field: "details", name: "Details", width: "250px" },
        { field: "changes", name: "Changes", width: "150px" },
        {
            formatter: (row) => {
                return (
                    <Typography variant="body2">
                        {formatdatetime(row.createdUtc)}
                    </Typography>
                );
            },
            name: "Created",
            width: "150px",
        },
        {
            formatter: (row) => {
                // const link = row.links.find((l) => l.rel === "self").href;
                return (
                    <Tooltip placement="top" title="Manage Organization">
                        <IconButton
                            onClick={() => {
                                setActivityId(row.logId);
                                setOpenViewActivity(true);
                            }}
                        >
                            <EyeIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
            name: "View",
            hideName: true,
            width: "60px",
            align: "center",
        },
    ];
    useEffect(() => {
        fetchActivities();

        return () => {};
    }, []);

    const fetchActivities = async () => {
        const result = await fetchData(settings.api().meActivity);
        if (result.success) {
            setActivities(result.data);
        }
    };

    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="h6">
                        {t("base.userView_site_title_siteActivity", "Site Activities")}
                    </Typography>
                }
                avatar={
                    <Avatar>
                        <ListIcon />
                    </Avatar>
                }
            />
            {(activities?.filteringOptions || activities?.sortingOptions) && (
                <>
                    <DataTableFilters
                        filters={activities?.filteringOptions}
                        sorts={activities?.sortingOptions}
                        initialSortDir={activities?.sortingOptions[0]?.field}
                    />
                    <Divider />
                </>
            )}
            <DataTable columns={columns} rows={activities?.items} />

            <DataTablePagination
                count={activities?.totalItems}
                pageNumber={activities?.pageNumber}
                pageSize={activities?.pageSize}
            />
            <ActivityLogDisplay handleOpen={(v) => setOpenViewActivity(v)} open={openViewActivity} logId={activityId} />
        </Card>
    );
};

export default Activity;
