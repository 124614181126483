import React, { useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";

export function DataTablePagination({ count = 0, pageSize = 25, pageNumber = 1, onPaginationChange }) {
    const { t } = useTranslation();
    const [page, setPage] = React.useState(pageNumber - 1);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);

    // Sync internal state with props when `pageSize` or `pageNumber` changes
    useEffect(() => {
        setPage(pageNumber - 1); // Convert 1-based to 0-based index
        setRowsPerPage(pageSize);
    }, [pageSize, pageNumber]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (onPaginationChange) {
            onPaginationChange({
                pageNumber: newPage + 1, // Convert 0-based to 1-based index
                pageSize: rowsPerPage,
            });
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
        if (onPaginationChange) {
            onPaginationChange({
                pageNumber: 1, // Reset page number to 1
                pageSize: newRowsPerPage,
            });
        }
    };

    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            labelRowsPerPage={t("base.list_rowsPerPage", "Rows per page")}
        />
    );
}
