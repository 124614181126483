import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Badge, Container, Icon, Menu, MenuItem, Paper, Tooltip } from "@mui/material";
import MenuByRole from "./menu/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import settings from "../settings/api";
import { connect } from "react-redux";
import { drawerClose, drawerToggle, updateMenuType } from "../settings/redux/componentsActions";
import formatPhone from "../Helpers/PhoneFormatter";
import PhoneIcon from "@mui/icons-material/Phone";
import formatPostalCode from "../Helpers/PostalCodeFormatter";
import { NotificationsPopover } from "./NotificationPopover";
import { usePopover } from "../Helpers/hooks/popover";
import { Bell as BellIcon } from "@phosphor-icons/react/dist/ssr/Bell";
import ExpandableSearchBar from "../components/ExpandableSearchBar";
import fetchData from "../Helpers/apiCalls";
import { loadTranslations } from "../locales/i18n";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: 0,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // marginTop: "75px"
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const AppBarManager = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, ...props }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: 36,
    marginTop: "139px",
    // backgroundColor: "orange",
    backgroundColor: props.backgroundcolor,
    color: props.foregroundcolor,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open
        ? {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: theme.transitions.create(["width", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
              }),
          }
        : {
              width: `calc(100% - 65px)`,
          }),
}));

const ToolbarManager = styled(Toolbar)({
    "@media all": {
        minHeight: 36,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const MenuProps = {
    PaperProps: {
        style: {
            backgroundColor: "rgba(50, 64, 82, 1)",
            color: "#e7ecfa",
        },
    },
};
function MiniDrawer(props) {
    const { children } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [profileMenuStructure, setProfileMenuStructure] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [portalInfo, setPortalInfo] = useState({});
    const [lang, setLang] = useState(0);
    const [userType, setUserType] = useState("admin");
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const openAccountMenu = Boolean(anchorEl);

    const [structureMenu, setStructureMenu] = useState({});
    const [currentView, setCurrentView] = useState("");

    const openLanguageMenu = Boolean(anchorElLanguage);
    const [locales, setLocales] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo")).locales);

    useEffect(() => {
        const menuType = localStorage.getItem("menuType");
        const userOrgInfo = JSON.parse(localStorage.getItem("userOrganizationInfo"));
        const userInfo = JSON.parse(localStorage.getItem("user"));

        setLang(userOrgInfo?.locales?.find((l) => l.id === userInfo?.localeId));

        switch (menuType) {
            case "sitesAdmin":
                getSiteAdminMenu();
                break;
            case "orgAdmin":
                getPortalAdminMenu();
                break;
            case "multiOrgAdmin":
                getGlobalAdminMenu();
                break;
            default:
                //     console.log("here user");
                //     getMenusStructure();
                break;
        }
        getMenusStructure();

        setUserInfo(userInfo);
        setPortalInfo(userOrgInfo);
        setCurrentView("/portal/");
        return () => {};
    }, []);

    useEffect(() => {
        // detects language change in other parts of the portal and updates the selected language in header. i.e. in /account
        const handleLanguageChange = (lng) => {
            const languageObj = JSON.parse(localStorage.getItem("userOrganizationInfo"))?.locales?.find(
                (l) => l.code === lng
            );
            setLang(languageObj);
        };

        i18n.on("languageChanged", handleLanguageChange);
    }, [i18n]);

    const handleDrawerOpen = () => {
        // setOpen(true);
        props.openAction();
    };
    const handleDrawerClose = () => {
        // setOpen(false);
        props.closeAction();
    };

    const handleOpenAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const menuType = localStorage.getItem("menuType");

        setUserType(menuType);
        if (menuType === "sitesAdmin") {
            setCurrentView("/admin/sites/");
            getSiteAdminMenu();
        } else if (menuType === "orgAdmin") {
            setCurrentView("/admin/organizations/");
            getPortalAdminMenu();
        } else if (menuType === "multiOrgAdmin") {
            setCurrentView("/admin/global/");
            getGlobalAdminMenu();
        } else {
            setCurrentView("/portal/");
            getMenusStructure();
        }
    }, [localStorage.getItem("menuType")]);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/login");
    };

    const handleLanguageChange = async (l) => {
        //update user locale via API
        const selectedLang = JSON.parse(l);

        const result = await fetchData(settings.api().updateLanguage, "PUT", { localeId: selectedLang.id });
        if (result.success) {
            // store new token
            localStorage.setItem("authToken", result?.data);

            // setLang gets set in useEffect instead when language change is detected
            // setLang(selectedLang);
            loadTranslations(selectedLang.code);

            // reload page to load new labels due to language change
            window.location.reload();

            // update user locale id in storage
            let userInfo = JSON.parse(localStorage.getItem("user"));
            userInfo = {
                ...userInfo,
                localeId: selectedLang.id,
            };
            localStorage.setItem("user", JSON.stringify(userInfo));
        }

        handleCloseLanguageMenu();
    };

    const handleChangeToStandard = () => {
        props.updateMenuType("user");
        localStorage.setItem("menuType", "user");
        localStorage.removeItem("organization");
        navigate("/portal");
        handleClose();
    };

    const handleChangeToAdmin = async (path) => {
        props.updateMenuType("sitesAdmin");
        localStorage.setItem("menuType", "sitesAdmin");

        navigate(path);

        handleClose();
    };

    const handleChangeToOrgAdmin = (path) => {
        handleClose();
        props.updateMenuType("orgAdmin");
        localStorage.setItem("menuType", "orgAdmin");
        navigate(path);
    };

    const handleChangeToMultiOrgAdmin = (path) => {
        handleClose();
        props.updateMenuType("multiOrgAdmin");
        localStorage.setItem("menuType", "multiOrgAdmin");

        navigate(path);
    };

    const getMenusStructure = async () => {
        const result = await fetchData(settings.api().structure, "GET");

        if (result.success) {
            const menuType = localStorage.getItem("menuType");
            menuType === "user" && setStructureMenu(result.data);
            setProfileMenuStructure(result.data.accountMenu);
        }
    };

    const getSiteAdminMenu = async () => {
        const result = await fetchData(settings.api().siteAdminMenu + `/${params.id}`, "GET");

        if (result.success) {
            setStructureMenu(result.data);
        }
    };

    const getPortalAdminMenu = async () => {
        const result = await fetchData(settings.api().portalAdminMenu + `/${params.id}`, "GET");

        if (result.success) {
            setStructureMenu(result.data);
        }
    };

    const getGlobalAdminMenu = async () => {
        const result = await fetchData(settings.api().systemAdminMenu, "GET");

        if (result.success) {
            setStructureMenu(result.data);
        }
    };

    const handleViewChange = (view) => {
        if (view.path.includes("/portal/")) {
            setCurrentView("/portal/");
            handleChangeToStandard(view.path);
        } else if (view.path.includes("/admin/sites/")) {
            setCurrentView("/admin/sites/");
            handleChangeToAdmin(view.path);
        } else if (view.path.includes("/admin/organizations/")) {
            setCurrentView("/admin/organizations/");
            handleChangeToOrgAdmin(view.path);
        } else if (view.path.includes("/admin/global/")) {
            setCurrentView("/admin/global/");
            handleChangeToMultiOrgAdmin(view.path);
        }
        // switch (view.path.includes()) {
        //     case "User View":
        //         setCurrentView("User View");
        //         handleChangeToStandard(view.path);
        //         break;
        //     case "Site Admin":
        //         setCurrentView("Site Admin");
        //         handleChangeToAdmin(view.path);
        //         break;
        //     case "Portal Admin":
        //         setCurrentView("Portal Admin");
        //         handleChangeToOrgAdmin(view.path);
        //         break;
        //     case "System Admin":
        //         setCurrentView("System Admin");
        //         handleChangeToMultiOrgAdmin(view.path);
        //         break;
        //     default:
        //         break;
        // }
    };

    const handleOpenLanguageMenu = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };
    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    };

    // console.log(structureMenu);

    return (
        <>
            <AppBar
                color="transparent"
                position="fixed"
                sx={{ height: "75px", boxShadow: "none", bgcolor: userType === "admin" ? "#2A2F38" : "white" }}
            >
                <Toolbar>
                    <img
                        src={portalInfo.theme?.logoSmallUri}
                        alt={portalInfo?.organizationName}
                        style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h6" noWrap component="div">
                        {portalInfo?.portalFullName}
                    </Typography>
                    <Divider orientation="vertical" flexItem style={{ margin: "15px 35px" }} />
                    <div style={{ marginRight: "45px" }}>
                        <Typography
                            color="inherit"
                            sx={{ whiteSpace: "nowrap" }}
                            variant="subtitle1"
                            fontWeight={"bold"}
                        >
                            {userInfo?.site?.name}
                        </Typography>{" "}
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.code}
                        </Typography>
                    </div>
                    <Box sx={{ flexGrow: 1, my: 2 }}>
                        <Typography
                            sx={{ whiteSpace: "nowrap" }}
                            color="text.secondary"
                            variant="body2"
                            component={"div"}
                        >
                            <PhoneIcon sx={{ fontSize: 16 }} style={{ verticalAlign: "middle" }} />{" "}
                            {formatPhone(userInfo?.site?.phonePrimary)}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.address?.address1}{" "}
                            {userInfo?.site?.address?.address2 && <>, {userInfo?.site?.address?.address2}</>}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.address?.city}{" "}
                            {userInfo?.site?.address?.postal && formatPostalCode(userInfo?.site?.address?.postal)}
                        </Typography>
                    </Box>

                    <IconButton
                        aria-label="language selected"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenLanguageMenu}
                        color="inherit"
                    >
                        <img src={lang.thumbUrl} alt={lang.name} style={{ width: "25px" }} />
                    </IconButton>
                    <Menu
                        open={openLanguageMenu}
                        id="menu-appbar"
                        anchorEl={anchorElLanguage}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        onClose={handleCloseLanguageMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        {locales?.length > 0 &&
                            locales?.map((l, i) => (
                                <MenuItem
                                    key={i}
                                    onClick={() => handleLanguageChange(JSON.stringify(l))}
                                    disabled={l.code === lang.code}
                                >
                                    <ListItemIcon>
                                        <img src={l.thumbUrl} alt={l.name} style={{ width: "15px" }} />
                                    </ListItemIcon>
                                    <ListItemText>{l.name}</ListItemText>
                                </MenuItem>
                            ))}
                    </Menu>
                    {/* <NotificationsButton /> */}
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenAccountMenu}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                        <Menu
                            open={openAccountMenu}
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            onClose={handleClose}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        width: "300px",
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ mx: 2 }}>
                                {profileMenuStructure?.title}
                            </Typography>
                            <Typography variant="body2" sx={{ mx: 2, mb: 2 }} color="text.secondary">
                                {profileMenuStructure?.subTitle}
                            </Typography>
                            <Divider />
                            {profileMenuStructure?.menuItems?.map((i, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        handleClose();
                                        i.label && props.updateMenuType("user");
                                        i.label && localStorage.setItem("menuType", "user");
                                        // i.label && localStorage.removeItem("organization");
                                        navigate(i.path);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon fontSize="small" color="primary">
                                            {i.icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText>{i.label}</ListItemText>
                                </MenuItem>
                            ))}
                            <Divider />
                            {profileMenuStructure?.viewItems?.map((i, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleViewChange(i)}
                                    disabled={i.path.includes(currentView)}
                                >
                                    <ListItemIcon>
                                        <Icon fontSize="small" color="primary">
                                            {i.icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {i.label}{" "}
                                        {i.path.includes(currentView) && `(${t("base.menu_selected", "current")})`}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem onClick={handleLogout}>
                                <ListItemText sx={{ textAlign: "center" }}>
                                    {t("base.menu_logout", "Logout")}
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: "flex" }}>
                <AppBar
                    open={props.open}
                    sx={{
                        height: "64px",
                        mt: "75px",
                        // bgcolor: userType !== "user" ? "#2A2F38" : "white"
                        bgcolor: structureMenu?.headerBackgroundColour ? structureMenu?.headerBackgroundColour : null,
                        color: structureMenu?.headerForegroundColour ? structureMenu?.headerForegroundColour : null,
                    }}
                    position="fixed"
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(props.open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {structureMenu?.headerImage ? (
                            <React.Fragment>
                                <img
                                    src={structureMenu?.headerImage}
                                    alt={structureMenu?.headerTitle}
                                    style={{ marginRight: "10px" }}
                                />
                                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                                    {!!structureMenu?.headerTitle && structureMenu?.headerTitle}
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                                {"Welcome " + userInfo.firstName + " " + userInfo.lastName}
                                {userInfo?.role?.code === "ADMIN" ||
                                    (userInfo?.role?.code === "MANAGER" && (
                                        <Typography variant="caption" component="span" sx={{ ml: 1 }}>
                                            ({userInfo?.role?.role})
                                        </Typography>
                                    ))}
                            </Typography>
                        )}
                        <ExpandableSearchBar />
                    </Toolbar>
                </AppBar>

                <Drawer
                    sx={{
                        "& .MuiPaper-root": {
                            scrollbarWidth: "none", // Firefox
                            "&::-webkit-scrollbar": {
                                display: "none", // Webkit browsers (Chrome, Safari)
                            },
                        },
                    }}
                    variant="permanent"
                    open={props.open}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#182434",
                            color: "#949ca7",
                        },
                    }}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon style={{ color: "#949ca7" }} />
                            ) : (
                                <ChevronLeftIcon style={{ color: "#949ca7" }} />
                            )}
                        </IconButton>
                    </DrawerHeader>

                    <MenuByRole userType={userType} structureMenu={structureMenu} />
                    {props.open && (
                        <Box sx={{ textAlign: "center" }}>
                            <Typography variant="caption" component="div">
                                {portalInfo?.environment}
                            </Typography>
                            <Typography variant="caption" component="div">
                                {process.env.REACT_APP_SAAS_API_URL}
                            </Typography>
                        </Box>
                    )}
                </Drawer>
                <Paper
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        minHeight: "100vh",
                        backgroundColor: userType !== "user" ? "#d6d6d6" : null,
                    }}
                >
                    <DrawerHeader style={{ height: userType === "user" ? "139px" : "160px" }} />
                    {userType !== "user" && (
                        <AppBarManager
                            open={props.open}
                            backgroundcolor={structureMenu?.titleBarBackgroundColour}
                            foregroundcolor={structureMenu?.titleBarForegroundColour}
                            elevation={0}
                            position="fixed"
                        >
                            <ToolbarManager>
                                <Typography variant="subtitle1" sx={{ fontWeight: 700, flexGrow: 1 }}>
                                    {structureMenu?.titleBarTitle}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                    {structureMenu?.titleBarDescription}
                                </Typography>
                            </ToolbarManager>
                        </AppBarManager>
                    )}

                    <Container maxWidth="xl" sx={{ mt: 3 }}>
                        {children}
                    </Container>
                </Paper>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        open: state.componentsReducer.drawerOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openAction: () => {
            dispatch(drawerToggle());
        },
        closeAction: () => {
            dispatch(drawerClose());
        },
        updateMenuType: (menuType) => {
            dispatch(updateMenuType(menuType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);

function NotificationsButton() {
    const popover = usePopover();

    return (
        <React.Fragment>
            <Tooltip title="Notifications">
                <Badge
                    color="error"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "& .MuiBadge-standard": {
                            right: "8px",
                            top: "6px",
                        },
                    }}
                    // variant="dot"
                    badgeContent={4}
                >
                    <IconButton sx={{ color: "text.primary" }} onClick={popover.handleOpen} ref={popover.anchorRef}>
                        <BellIcon />
                    </IconButton>
                </Badge>
            </Tooltip>
            <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </React.Fragment>
    );
}
