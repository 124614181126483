import { getDomain, getSubdomain } from "./getSubdomain";

const fetchData = async (url, method, body = null) => {
    const token = localStorage.getItem("authToken");    
    const domain = getDomain();
    const subdomain = getSubdomain();

    try {
        const options = {
            method,
            headers: {
                "Content-Type": "application/json",
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${token}`,
            },
        };
        // console.log(JSON.stringify(body));

        // Include body for POST, PUT, or PATCH methods
        if (body) {
            options.body = JSON.stringify(body);
        }
        
        const response = await fetch(url, options);
        // console.log(url);

        // Parse and return JSON data
        // const result = await response.json().catch(() => null);
        const result = await response.json();

        // console.log(result);

        return result;
        // return {
        //     success: true,
        //     data: result || null, // Include data if available
        // };
    } catch (error) {
        // console.log(error);
        
        // Log error for debugging and return error message
        console.error("Fetching Data Error:", error);
        // return { message: error?.message || "An unknown error occurred", errors: error?.errors };
        return error;
    }
};

export default fetchData;

export const fetchPublicData = async (url, method, body = null) => {
    const domain = getDomain();
    const subdomain = getSubdomain();

    try {
        const options = {
            method,
            headers: {
                "Content-Type": "application/json",
                "X-Domain": domain,
                "X-Tenant": subdomain,
            },
        };
        // Include body for POST, PUT, or PATCH methods
        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);

        // Parse and return JSON data
        const result = await response.json();

        return result;
    } catch (error) {        
        // Log error for debugging and return error message
        console.error("Fetching Data Error:", error);
        return error;
    }
};
