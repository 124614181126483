import React from "react";
import { Avatar, Card, CardContent, CardHeader, TextField, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { Equalizer as EqualizerIcon } from "@phosphor-icons/react/dist/ssr/Equalizer";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";

const columns = [
    { field: "productName", name: "Product Name", width: "100px" },
    { field: "size", name: "Size", width: "60px" },
    { field: "totalQty", name: "Total Qty.", width: "60px" },
    { formatter: (row) => <TextField size="small" value={0} />, name: "Aug. Delivery", width: "70px" },
    { formatter: (row) => <TextField size="small" value={0} />, name: "Sept. Delivery", width: "70px" },
    { formatter: (row) => <TextField size="small" value={0} />, name: "Oct. Delivery", width: "70px" },
    { formatter: (row) => <TextField size="small" value={0} />, name: "Nov. Delivery", width: "70px" },
    { formatter: (row) => <TextField size="small" value={0} />, name: "Dec. Delivery", width: "70px" },
];

const rows = [
    {
        productName: "X-Ice",
        size: "225/60R17",
        totalQty: 70,
    },
    {
        productName: "Latitude X-Ice",
        size: "235/65R17",
        totalQty: 85,
    },
];

export default function AllocatedQuantities() {
    return (
        <Card>
            <CardHeader
                title={<Typography variant="h6">Allocate Tire Quantities</Typography>}
                avatar={
                    <Avatar>
                        <EqualizerIcon size={24} />
                    </Avatar>
                }
            />
            <CardContent>
                <DataTable dense={true} columns={columns} rows={rows} />
                <DataTablePagination count={2} pageNumber={1} pageSize={25} />
            </CardContent>
        </Card>
    );
}
