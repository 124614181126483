import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserGear as UserGearIcon } from "@phosphor-icons/react/dist/ssr/UserGear";
import dayjs from "dayjs";
import BootstrapInput from "../../../components/BootstrapTextField";
import BootstrapSelect from "../../../components/BootstrapSelect";
import settings from "../../../settings/api";
import fetchData from "../../../Helpers/apiCalls";
import { useParams } from "react-router-dom";
import AccountLayout from "../Account";

const UserProfile = () => {
    const { id } = useParams();
    const [timezones, setTimezones] = useState([]);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        phone: "",
        position: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timeZoneId: 0,
    });

    useEffect(() => {
        fetchUser();

        return () => {};
    }, []);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const updateUser = async () => {
        const body = {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            phone: userInfo.phone,
            localeId: userInfo.localeId,
            timeZoneId: userInfo.timeZoneId,
        };

        const result = await fetchData(settings.api().users, "PUT", body);

        if (result.success) {
            window.location.reload();
        }
    };

    const fetchUser = async () => {
        const result = await fetchData(settings.api().users + `/${id}`, "GET");
        // console.log(result);

        if (result.success) {
            setUserInfo({
                ...result.data,
                localeId: result?.data?.locale?.selectedId,
                timeZoneId: result?.data?.timeZone?.selectedId,
            });

            if (!result?.data?.timeZone?.useStaticValues) {
                fetchTimeZones(result?.data?.timeZone?.link?.href, result?.data?.timeZone?.link?.method);
            }
        }
    };
    // console.log(data);

    const fetchTimeZones = async (path, method) => {
        const result = await fetchData(settings.api().route + path, method);
        if (result.success) {
            setTimezones(result.data);
        }
    };

    const navItems = [
        {
            key: "personal",
            title: "Personal",
            items: [
                { key: "account", title: "Account", href: `/portal/site/users/${id}`, icon: "user-circle" },
                { key: "security", title: "Security", href: `/portal/site/users/${id}/security`, icon: "lock-key" },
            ],
        },
    ];
    // console.log(userInfo?.locale);

    return (
        <AccountLayout
            navItems={navItems}
            profile={{ name: `${userInfo.firstName} ${userInfo.lastName}`, secondary: userInfo.login }}
        >
            <div>
                <div>
                    <Typography variant="h4">Manage User</Typography>
                </div>
                <Card sx={{ mt: 4 }}>
                    <CardHeader
                        // avatar={<Avatar>Logo</Avatar>}
                        title={
                            <>
                                <Stack direction="row" spacing={2} sx={{ alignItems: "center", flex: "1 1 auto" }}>
                                    <Avatar src="/assets/avatar-1.png" sx={{ width: 64, height: 64 }}>
                                        Logo
                                    </Avatar>
                                    <div>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ alignItems: "center", flexWrap: "wrap" }}
                                        >
                                            <Typography variant="h4">{userInfo?.siteName}</Typography>
                                        </Stack>
                                        <Typography color="textSecondary" variant="body1">
                                            {userInfo?.siteCode} site_code
                                        </Typography>
                                    </div>
                                </Stack>
                            </>
                        }
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}
                            sx={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Role
                                </Typography>
                                <Chip
                                    icon={<UserGearIcon color="#15b79f" weight="fill" />}
                                    label={userInfo?.role}
                                    size="small"
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Employee Code
                                </Typography>
                                <Typography variant="subtitle1">{userInfo?.code}12838DQ83DA</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Department
                                </Typography>
                                <Typography variant="subtitle1">
                                    {/* {userInfo?.department} */}
                                    deparment
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Position
                                </Typography>
                                <Typography variant="subtitle1">
                                    {/* {userInfo?.position} */}
                                    position
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Account Created
                                </Typography>
                                <Typography variant="subtitle1">
                                    {dayjs(userInfo?.createUtc).format("MMM D, YYYY")}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 4 }}>
                    <CardHeader
                        avatar={<Avatar>{/* <StorefrontIcon fontSize="large" /> */}</Avatar>}
                        title={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                    Basic Details
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    display={"inline"}
                                    sx={{ marginRight: "5px" }}
                                >
                                    Last modified: {dayjs(userInfo.modifiedUtc).format("MMM DD, YYYY")}
                                </Typography>
                            </Box>
                        }
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Typography variant="subtitle1">My Information</Typography>
                        <Divider />
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                display: "grid",
                                gridTemplateColumns: { sm: "1fr 1fr" },
                                gap: 2,
                                py: 2,
                            }}
                        >
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>First Name</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"firstName"}
                                    value={userInfo.firstName}
                                    onChange={handleUserInfoChange}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Last Name</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"lastName"}
                                    value={userInfo.lastName}
                                    onChange={handleUserInfoChange}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Email</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"email"}
                                    type="email"
                                    onChange={handleUserInfoChange}
                                    value={userInfo.email}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Login
                                </InputLabel>
                                <BootstrapInput name={"login"} value={userInfo.login} onChange={handleUserInfoChange} />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Phone Number
                                </InputLabel>
                                <BootstrapInput
                                    name={"phone"}
                                    type="tel"
                                    value={userInfo.phone}
                                    onChange={handleUserInfoChange}
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Profile Public"
                                checked={userInfo?.profilePublic}
                                onChange={() =>
                                    setUserInfo({
                                        ...userInfo,
                                        profilePublic: !userInfo.profilePublic,
                                    })
                                }
                            />
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    <strong>Language</strong>
                                </InputLabel>
                                <BootstrapSelect
                                    value={userInfo?.localeId}
                                    onChange={handleUserInfoChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Language--</em>
                                    </MenuItem>
                                    {userInfo?.locale?.useStaticValues &&
                                        userInfo?.locale?.values?.map((l) => (
                                            <MenuItem key={l.id} value={l.id}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                </BootstrapSelect>
                            </FormControl>
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    <strong>Time Zone</strong>
                                </InputLabel>
                                <BootstrapSelect
                                    value={userInfo.timeZoneId}
                                    name={"timeZoneId"}
                                    onChange={handleUserInfoChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Time Zone--</em>
                                    </MenuItem>
                                    {timezones.map((t) => (
                                        <MenuItem key={t.id} value={t.id}>
                                            {t.name}
                                        </MenuItem>
                                    ))}
                                </BootstrapSelect>
                            </FormControl>
                        </Box>
                        <div style={{ marginTop: "0px" }}>
                            <Button variant="outlined" style={{ marginRight: "10px" }} onClick={updateUser}>
                                Update
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </AccountLayout>
    );
};

export default UserProfile;
