import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OrderQuantities from "./OrderQuantities";
import { Avatar, Card, CardContent, CardHeader } from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AllocatedQuantities from "./AllocatedQuantities";
import SelectedDistributors from "./SelectedDistributors";
import OrderReview from "./OrderReview";
import fetchData from "../../../../Helpers/apiCalls";
import settings from "../../../../settings/api";
import { useParams } from "react-router-dom/dist";
import { useEffect } from "react";
import { useState } from "react";

const steps = ["Order Quantities", "Allocate Quantities", "Select Distributors", "Review Order"];
const stepContent = [<OrderQuantities />, <AllocatedQuantities />, <SelectedDistributors />, <OrderReview />];

export default function BookingWizard() {
    const { id, bookingId } = useParams();
    const [booking, setBooking] = useState({});

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        !!bookingId && fetchBooking();
        // fetchBookingProducts();

        return () => {};
    }, []);
    const fetchBooking = async () => {
        console.log(settings.api(id).tpoBookings + `/${bookingId}`);
        const result = await fetchData(settings.api(id).tpoBookings + `/${bookingId}`);
        console.log(result);
        if (result.success) {
            setBooking(result.data);
        }
    };

    const fetchBookingProducts = async () => {
        console.log(settings.api(id).tpoBookings + `/${bookingId}/products`);
        const result = await fetchData(settings.api(id).tpoBookings + `/${bookingId}/products`);
        console.log(result);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <LibraryBooksIcon />
                    </Avatar>
                }
                title={
                    <>
                        <Typography variant="h6">Booking</Typography>
                        <Typography variant="subtitle1">{booking?.siteName}</Typography>
                        <Typography variant="subtitle1">{booking?.productType}, {booking?.scheduleType}</Typography>
                    </>
                }
                subheader={
                    <>
                        <Typography variant="subtitle2">Total Products: {booking?.totalProducts}</Typography>
                        <Typography variant="subtitle2">Total Quantities: {booking?.totalQuantities}</Typography>
                    </>
                }
            />
            <CardContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((s, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{s}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Progress has been saved./ Order has been submitted.
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ mt: 3, mb: 1 }}>{stepContent[activeStep]}</Box>

                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button
                                color="inherit"
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {activeStep === steps.length - 1 && (
                                <Button onClick={handleNext} variant="contained">
                                    Submit
                                </Button>
                            )}
                            {activeStep !== steps.length - 1 && (
                                <Box>
                                    <Button onClick={() => setActiveStep(4)} sx={{ mr: 1 }} variant="outlined">
                                        Save
                                    </Button>
                                    <Button variant="contained" onClick={handleNext}>
                                        Next
                                    </Button>
                                </Box>
                            )}
                            {/* <Button onClick={handleNext}>{activeStep === steps.length - 1 ? "Submit" : "Next"}</Button> */}
                        </Box>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
}
