import React, { useEffect, useState } from "react";
import CopyRight from "../components/CopyRight";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { getDomain, getSubdomain } from "../Helpers/getSubdomain";
import styled from "@emotion/styled";
import settings from "../settings/api";
import fetchData from "../Helpers/apiCalls";

const StyledContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(2, 4, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

export default function Login() {
    const domain = getDomain();
    const subdomain = getSubdomain();

    const navigate = useNavigate();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [lang, setLang] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [portalInfo, setPortalInfo] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const openLanguageMenu = Boolean(anchorEl);

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (name === "email") {
            setLogin(value);
        } else {
            setPassword(value);
        }
    };

    useEffect(() => {
        getPortalBranding();
        return () => {};
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        const credentials = {
            login,
            password,
        };

        fetch(settings.api().authenticate, {
            method: "POST",
            body: JSON.stringify(credentials),
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    return res.json();
                } else {
                    console.log("throwing!");
                    localStorage.clear();
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                // console.log(data);
                if (data.redirection) {
                    let url = "/" + data.redirection.page;
                    const info = {
                        title: data.redirection.title,
                        description: data.redirection.description,
                    };
                    const searchParams = new URLSearchParams();
                    searchParams.set("userCode", data.redirection.userCode);
                    searchParams.set("validationCode", data.redirection.validationCode);
                    searchParams.size > 0
                        ? navigate(`${url}?${searchParams.toString()}`)
                        : navigate(url, { state: info });
                } else {
                    localStorage.setItem("authToken", data.token);
                    localStorage.setItem("userOrganizationInfo", JSON.stringify(portalInfo));

                    localStorage.setItem("user", JSON.stringify(data));
                    localStorage.setItem("menuType", "user");

                    navigate("/portal");
                }
            })
            .catch((err) => {
                console.error(err);
                alert("Error logging in please try again");
            });
    };

    const getPortalBranding = async () => {
        const result = await fetchData(settings.api().portalInfo, "GET");

        if (result.success) {
            localStorage.setItem("userOrganizationInfo", JSON.stringify(result.data));
            setLang(result.data.locales);
            setSelectedLanguage(result.data.locales[0]);

            // update tab title
            document.title = result.data.portalTitle;

            // get favicon element
            let link = document.querySelector("link[rel~='icon']");
            // if favicon did exist, create one
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }
            // update favicon
            link.href = result.data.theme.favIconUri;
            setPortalInfo(result.data);
        }
        // const result = fetch(settings.api().portalInfo, {
        //     headers: {
        //         "X-Domain": domain,
        //         "X-Tenant": subdomain,
        //     },
        // })
        //     .then((res) => {
        //         // console.log(res);
        //         if (res.status === 200) {
        //             return res.json();
        //         } else {
        //             const error = new Error(res.error);
        //             throw error;
        //         }
        //     })
        //     .then((data) => {
        //         console.log(data);
        //         localStorage.setItem("userOrganizationInfo", JSON.stringify(data));
        //         setLang(data.locales);
        //         setSelectedLanguage(data.locales[0]);

        //         // update tab title
        //         document.title = data.portalTitle;

        //         // get favicon element
        //         let link = document.querySelector("link[rel~='icon']");
        //         // if favicon did exist, create one
        //         if (!link) {
        //             link = document.createElement("link");
        //             link.rel = "icon";
        //             document.getElementsByTagName("head")[0].appendChild(link);
        //         }
        //         // update favicon
        //         link.href = data.theme.favIconUri;
        //         setPortalInfo(data);
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     });
        return result;
    };

    const handleOpenLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (l) => {
        setSelectedLanguage(l);
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                // xs={false}
                // xs={0}
                // sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${portalInfo?.theme?.loginImageUri})`,
                    backgroundRepeat: "no-repeat",
                    bgcolor: "grey[50]",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} md={5} component={Paper} square>
                <Grid container justifyContent={"center"} style={{ marginTop: "10px", marginRight: "10px" }}>
                    <Grid item xs align="right">
                        <div>
                            {/* <Button onClick={handleOpenLanguageMenu}>
                                <img src={lang.thumbUrl} alt={lang.name} style={{ width: "20px" }} />
                            </Button> */}
                            <Button
                                // size="large"
                                aria-label="language selected"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenLanguageMenu}
                                color="inherit"
                            >
                                <img
                                    src={selectedLanguage.thumbUrl}
                                    alt={selectedLanguage.name}
                                    style={{ width: "20px" }}
                                />
                            </Button>
                            <Menu
                                open={openLanguageMenu}
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                onClose={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                            >
                                {lang.length > 0 &&
                                    lang.map((l, i) => (
                                        <MenuItem key={i} onClick={() => handleLanguageChange(l)}>
                                            <ListItemIcon>
                                                <img src={l.thumbUrl} alt={l.name} style={{ width: "15px" }} />
                                            </ListItemIcon>
                                            <ListItemText>{l.name}</ListItemText>
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </div>
                        {/* <ButtonGroup variant="outlined" aria-label="language button group">
                            {portalInfo?.locales?.map((l) => (
                                <Button
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img src={l.thumbUrl} alt={l.name} style={{ width: "15px" }} />
                                    &nbsp;{l.name}
                                </Button>
                            ))}
                        </ButtonGroup> */}
                    </Grid>
                </Grid>
                <StyledContainer
                    style={{
                        // minHeight: `calc(100vh - 120px)` /* will cover the 100% of viewport */,
                        overflow: "hidden",
                        display: "block",
                        position: "relative",
                        // paddingBottom: "100px" /* height of your footer */,
                    }}
                >
                    <div style={{ display: "grid", justifyContent: "center", width: "100%" }}>
                        <img
                            src={portalInfo?.theme?.logoLargeUri}
                            alt="Saphera Software"
                            style={{
                                margin: "auto",
                            }}
                        />
                        <Typography variant="h6" gutterBottom>
                            {portalInfo?.portalFullName}
                        </Typography>
                    </div>
                    <Typography component="h1" variant="h5" style={{ marginTop: "40px" }}>
                        Sign in
                    </Typography>
                    <form
                        style={{ width: "100%", minHeight: "calc(100vh - 390.038px)" }}
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <TextField
                            variant="outlined"
                            type="email"
                            name="email"
                            margin="normal"
                            fullWidth
                            label={
                                portalInfo.loginType === "EMAIL"
                                    ? "Enter email"
                                    : portalInfo.loginType === "USERNAME"
                                    ? "Enter username"
                                    : "Enter email/username"
                            }
                            autoComplete="email"
                            autoFocus
                            value={login}
                            required
                            onChange={handleInputChange}
                            id="login"
                        />
                        <TextField
                            variant="outlined"
                            type="password"
                            name="password"
                            margin="normal"
                            fullWidth
                            label="Enter password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handleInputChange}
                            required
                            id="password"
                        />
                        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                        <Button
                            sx={{ mt: 3, mb: 2 }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            value="submit"
                        >
                            Sign In
                        </Button>
                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                                <Link href="/" target="_blank" variant="body2">
                                    {"Don't have an account?"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </form>

                    <CopyRight copyrightName={portalInfo.copyrightName} copyrightUrl={portalInfo.copyrightUrl} />
                </StyledContainer>
            </Grid>
        </Grid>
    );
}
