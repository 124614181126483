import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { DataTable } from "../../../components/DataTable/DataTable";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import fetchData from "../../../Helpers/apiCalls";
import settings from "../../../settings/api";
import { useParams } from "react-router-dom";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function TPODeliveryDateSettings() {
    const { id } = useParams();
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [openEditTPOSchedule, setOpenEditTPOSchedule] = useState(false);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState({});

    const columns = [
        { field: "productType", name: "Product Type", width: "100px" },
        { field: "season", name: "Season", width: "100px" },
        {
            formatter: (row) => {
                const result = Object.entries(row.months);
                return (
                    <Stack direction={"row"} spacing={0.5} sx={{ alignItems: "center" }}>
                        {result.map((m, i) => (
                            <Box
                                key={i}
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    bgcolor: m[1] ? "#ccfbef" : "rgba(0, 0, 0, 0.08)",
                                    color: m[1] ? "#107569" : "rgba(0, 0, 0, 0.87)",
                                    borderRadius: "10px",
                                    width: "60px",
                                    height: "50px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center", // Centers items horizontally
                                    justifyContent: "center", // Centers items vertically
                                }}
                            >
                                <Typography variant="subtitle1">{m[0]}</Typography>
                            </Box>
                        ))}
                    </Stack>
                );
            },
            name: "Selected Months",
            width: "250px",
        },
        {
            formatter: (row) => (
                <IconButton
                    onClick={() => {
                        setSelectedSchedule(row);
                        setOpenEditTPOSchedule(true);
                    }}
                >
                    <PencilSimpleIcon />
                </IconButton>
            ),
            name: "Edit",
            width: "80px",
        },
    ];

    useEffect(() => {
        getTPODeliveryDates();

        return () => {};
    }, []);

    const handleSelectedMonthChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedMonths(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const getTPODeliveryDates = async () => {
        const result = await fetchData(settings.api(id).tpoDeliveryDates, "GET");
        // console.log(result);

        if (result.success) {
            setDeliveryDates(result.data);
        }
    };
    // console.log(deliveryDates);

    return (
        <Box>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <LocalShippingIcon />
                        </Avatar>
                    }
                    title={<Typography variant="h6">TPO Delivery Date Settings</Typography>}
                />
                <CardContent>
                    <DataTable columns={columns} rows={deliveryDates} />
                </CardContent>
            </Card>
            <Dialog open={openEditTPOSchedule} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>Edit {selectedSchedule.season + " - " + selectedSchedule.productType}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id="multiple-months-checkbox-label">Months Selected</InputLabel>
                        <Select
                            labelId="multiple-months-checkbox-label"
                            multiple
                            value={selectedMonths}
                            onChange={handleSelectedMonthChange}
                            input={<OutlinedInput label="Months Selected" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {months.map((m) => (
                                <MenuItem key={m} value={m}>
                                    <Checkbox checked={selectedMonths.includes(m)} />
                                    <ListItemText primary={m} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditTPOSchedule(false)}>Cancel</Button>
                    <Button onClick={() => setOpenEditTPOSchedule(false)} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
