// import React, { useEffect, useState } from "react";
// import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
// import {
//     Box,
//     Button,
//     CardHeader,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     Divider,
//     IconButton,
//     Stack,
//     styled,
//     Tooltip,
//     Typography,
// } from "@mui/material";
// import { DataTable } from "../../../../components/DataTable/DataTable";
// import { DataTableFilters } from "../../../../components/DataTable/DataTableFilters";
// import fetchData from "../../../../Helpers/apiCalls";
// import settings from "../../../../settings/api";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import UploadIcon from "@mui/icons-material/Upload";
// import DownloadIcon from "@mui/icons-material/Download";
// import { useRef } from "react";
// import { getDomain, getSubdomain } from "../../../../Helpers/getSubdomain";
// import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
// import { useDataTable } from "../../../../Helpers/hooks/useDataTable";
// // import useDataTable from "../../../../Helpers/hooks/useDataTable";

// const VisuallyHiddenInput = styled("input")({
//     clip: "rect(0 0 0 0)",
//     clipPath: "inset(50%)",
//     height: 1,
//     overflow: "hidden",
//     position: "absolute",
//     bottom: 0,
//     left: 0,
//     whiteSpace: "nowrap",
//     width: 1,
// });

// const TPOData = () => {
//     const { id: scheduleId } = useParams();
//     const navigate = useNavigate();
//     const [data, setData] = useState({items:[]});
//     const [file, setFile] = useState(null);
//     const [openUpload, setOpenUpload] = useState(false);
//     const fileInputRef = useRef(null);
//     const [isUploadSuccessful, setIsUploadSuccessful] = useState("");
//     const [columns, setColumns] = useState([]);
//     const location = useLocation();
//     // filters
//     const [filters, setFilters] = useState({});
//     const [sorts, setSorts] = useState("");
//     const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 25 });

//     const [loading, setLoading] = useState(true);

//     // const fetchScheduleData = async (query) => {
//     //     setLoading(true);
//     //     const url = settings.api(scheduleId).tpoScheduleData + query;
//     //     const result = await fetchData(url, "GET");
//     //     setLoading(false);
//     //     return result;
//     // };
//     const fetchScheduleData = async (query) => {
//         setLoading(true);
//         const url = settings.api(scheduleId).tpoScheduleData + query;
//         const result = await fetchData(url, "GET");
//         if (result.succes) {
//         }
//         setLoading(false);
//     };
//     const { paginationState, handlePaginationChange, handleFilterUpdate } = useDataTable({
//         fetchData: fetchScheduleData,
//         filters,
//         sorts,
//         pagination,
//         setPagination,
//         setFilters,
//         setSorts,
//     });
//     // Fetch data when URL search string changes
//     useEffect(() => {
//         fetchDataFromAPI(location.search);
//     }, [location.search]);

//     // Fetch data function
//     const fetchDataFromAPI = async (query) => {
//         const url = settings.api(scheduleId).tpoScheduleData + query;
//         const result = await fetchData(url, "GET");

//         if (result.success) {
//             setData(result.data);
//             result.data.columns.length > 0 && createColumns(result.data.columns);
//         }
//     };

//     console.log(data);

//     // pagination states
//     // const [pagination, setPagination] = useState({
//     //     pageNumber: 1,
//     //     pageSize: 25,
//     // });

//     // useEffect(() => {
//     //     fetchScheduleData(location.search);

//     //     return () => {};
//     // }, [location.search]);

//     // const fetchScheduleData = async (query) => {
//     //     // console.log(location.search, !!location.search);

//     //     // const query =
//     //     //     location.search !== ""
//     //     //         ? location.search
//     //     //         : // + `&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`
//     //     //           `?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`;
//     //     // const url = settings.api(scheduleId).tpoScheduleData + query;
//     //     // console.log(query);

//     //     // const query = updateSearchParams(filters, sorts, pagination); // Construct the query string
//     //     const url = settings.api(scheduleId).tpoScheduleData + query;
//     //     // console.log(query, url);

//     //     const result = await fetchData(url, "GET");
//     //     // console.log(result.data);

//     //     if (result.success) {
//     //         setData(result.data);
//     //         result.data.columns.length > 0 && createColumns(result.data.columns);
//     //     }
//     // };

//     // useEffect(() => {
//     //     fetchScheduleData(location.search);

//     //     return () => {};
//     // }, [pagination.pageNumber, pagination.pageSize]);

//     const handleFileChange = (event) => {
//         const selectedFile = event.target.files[0];

//         if (selectedFile) {
//             setFile(selectedFile);
//             setOpenUpload(true);
//         }
//     };

//     // reset file upload
//     const handleReset = () => {
//         if (fileInputRef.current) {
//             fileInputRef.current.value = ""; // Clear the file input's value
//         }
//         setFile(null);
//     };

//     const UploadCsvFile = async () => {
//         const formData = new FormData();
//         formData.append("file", file);

//         const token = localStorage.getItem("authToken");
//         const domain = getDomain();
//         const subdomain = getSubdomain();

//         fetch(settings.api(scheduleId).importScheduleData, {
//             headers: {
//                 "X-Domain": domain,
//                 "X-Tenant": subdomain,
//                 Authorization: `Bearer ${token}`,
//             },

//             method: "POST",
//             body: formData,
//         }).then((res) => {
//             console.log(res);
//             if (res.ok) {
//                 setIsUploadSuccessful("File has be uploaded successfully.");
//             }
//         });
//     };

//     const createColumns = (cols) => {
//         // make sure its in the right order then, create each column
//         let tmp = cols
//             .sort((a, b) => a.order - b.order)
//             .map((c) => ({ field: c.field, name: c.label, width: `${c.width}%` }));

//         // add the view column
//         tmp = [
//             ...tmp,
//             {
//                 formatter: (row) => {
//                     return (
//                         <Box>
//                             <Tooltip placement="top" title="View">
//                                 <IconButton
//                                     onClick={() => {
//                                         // setActivityId(row.logId);
//                                         // setOpenViewActivity(true);
//                                     }}
//                                 >
//                                     <EyeIcon />
//                                 </IconButton>
//                             </Tooltip>
//                         </Box>
//                     );
//                 },
//                 name: "View",
//                 hideName: true,
//                 width: "100px",
//                 align: "center",
//             },
//         ];
//         setColumns(tmp);
//     };

//     // const updateSearchParams = (filters, sorts, pagination) => {
//     //     const params = new URLSearchParams();

//     //     // Add filters
//     //     Object.entries(filters).forEach(([key, value]) => {
//     //         if (value) params.set(key, value);
//     //     });

//     //     // Add sortDir if provided
//     //     if (sorts) {
//     //         params.set("sortDir", sorts);
//     //     }

//     //     // Add pagination
//     //     params.set("pageNumber", pagination.pageNumber);
//     //     params.set("pageSize", pagination.pageSize);

//     //     // Update the URL
//     //     const queryString = `?${params.toString()}`;
//     //     window.history.replaceState(null, "", queryString);
//     //     console.log(queryString);

//     //     return queryString;
//     // };

//     // console.log(filters, sorts, pagination);

//     // const handlePaginationChange = ({ pageNumber, pageSize }) => {
//     //     setPagination({ pageNumber, pageSize });

//     //     // Update search params and fetch new data
//     //     const query = updateSearchParams(filters, sorts, { pageNumber, pageSize });

//     //     fetchScheduleData(query);
//     // };

//     // const handleFilterUpdate = (newFilters, newSortDir) => {
//     //     setFilters(newFilters); // Update filters and sorts
//     //     setSorts(newSortDir); // Update sorts
//     //     setPagination({ pageNumber: 1, pageSize: 25 });
//     //     const query = updateSearchParams(newFilters, newSortDir, { pageNumber: 1, pageSize: pagination.pageSize });

//     //     fetchScheduleData(query); // Trigger a data fetch
//     // };

//     console.log(data, data.items, data?.items?.length > 0);

//     return (
//         <div>
//             <CardHeader
//                 title={<Typography variant="h6"></Typography>}
//                 action={
//                     <Stack spacing={1} direction={"row"}>
//                         <Button
//                             component="label"
//                             role={undefined}
//                             variant="contained"
//                             tabIndex={-1}
//                             startIcon={<UploadIcon />}
//                         >
//                             Upload CSV
//                             <VisuallyHiddenInput
//                                 type="file"
//                                 ref={fileInputRef}
//                                 onChange={handleFileChange}
//                                 accept=".csv"
//                             />
//                         </Button>
//                         <Button variant="contained" startIcon={<DownloadIcon />}>
//                             Download CSV
//                         </Button>
//                     </Stack>
//                 }
//             />

//             {(data?.filteringOptions || data?.sortingOptions) && (
//                 <>
//                     <DataTableFilters
//                         filters={data?.filteringOptions}
//                         sorts={data?.sortingOptions}
//                         initialSortDir={data?.sortingOptions[0]?.field}
//                         onFilterChange={handleFilterUpdate}
//                         onClearFilters={() => handleFilterUpdate({}, data?.sortingOptions[0]?.field)}
//                     />
//                     <Divider />
//                 </>
//             )}
//             {data?.items?.length > 0 ? (
//                 <DataTable columns={columns} rows={data?.items} />
//             ) : (
//                 <Box sx={{ p: 3 }}>
//                     <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
//                         No records found
//                     </Typography>
//                 </Box>
//             )}
//             <DataTablePagination
//                 count={data?.totalItems || 0} // Total rows from API
//                 pageSize={pagination.pageSize}
//                 pageNumber={pagination.pageNumber}
//                 onPaginationChange={handlePaginationChange}
//             />
//             <Dialog
//                 open={openUpload}
//                 onClose={() => {
//                     handleReset();
//                     setOpenUpload(false);
//                     setIsUploadSuccessful("");
//                 }}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//             >
//                 <DialogTitle id="alert-dialog-title">Upload CSV File</DialogTitle>
//                 <DialogContent>
//                     <Typography variant="body1">
//                         Selected file: <strong>{file?.name}</strong>
//                     </Typography>
//                     <Typography
//                         variant="body1"
//                         // color="success"
//                         sx={{ color: "#15b79f" }}
//                     >
//                         {isUploadSuccessful}
//                     </Typography>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button
//                         onClick={() => {
//                             handleReset();
//                             setOpenUpload(false);
//                             setIsUploadSuccessful("");
//                         }}
//                     >
//                         Close
//                     </Button>
//                     <Button onClick={UploadCsvFile} variant="contained">
//                         Upload
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default TPOData;

import React, { useEffect, useState } from "react";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
import {
    Box,
    Button,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    styled,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTableFilters } from "../../../../components/DataTable/DataTableFilters";
import fetchData from "../../../../Helpers/apiCalls";
import settings from "../../../../settings/api";
import { useParams } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { useRef } from "react";
import { getDomain, getSubdomain } from "../../../../Helpers/getSubdomain";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import useDataTable from "../../../../Helpers/hooks/useDataTable";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const TPOData = () => {
    const { id: scheduleId } = useParams();
    const [file, setFile] = useState(null);
    const [openUpload, setOpenUpload] = useState(false);
    const fileInputRef = useRef(null);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState("");
    const [columns, setColumns] = useState([]);

    const fetchDataFromApi = async (query) => {
        const url = settings.api(scheduleId).tpoScheduleData + query;
        const result = await fetchData(url, "GET");
        return result;
    };

    const { data, pagination, handlePaginationChange, handleFilterUpdate } = useDataTable(fetchDataFromApi);

    useEffect(() => {
        data?.columns.length > 0 && createColumns(data?.columns);

        return () => {};
    }, [data]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setFile(selectedFile);
            setOpenUpload(true);
        }
    };

    // reset file upload
    const handleReset = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the file input's value
        }
        setFile(null);
    };

    const UploadCsvFile = async () => {
        const formData = new FormData();
        formData.append("file", file);

        const token = localStorage.getItem("authToken");
        const domain = getDomain();
        const subdomain = getSubdomain();

        fetch(settings.api(scheduleId).importScheduleData, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${token}`,
            },

            method: "POST",
            body: formData,
        }).then((res) => {
            console.log(res);
            if (res.ok) {
                setIsUploadSuccessful("File has be uploaded successfully.");
            }
        });
    };

    const createColumns = (cols) => {
        // make sure its in the right order then, create each column
        let tmp = cols
            .sort((a, b) => a.order - b.order)
            .map((c) => ({ field: c.field, name: c.label, width: `${c.width}%` }));

        // add the view column
        tmp = [
            ...tmp,
            {
                formatter: (row) => {
                    return (
                        <Box>
                            <Tooltip placement="top" title="View">
                                <IconButton
                                    onClick={() => {
                                        // setActivityId(row.logId);
                                        // setOpenViewActivity(true);
                                    }}
                                >
                                    <EyeIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
                name: "View",
                hideName: true,
                width: "100px",
                align: "center",
            },
        ];
        setColumns(tmp);
    };

    return (
        <div>
            <CardHeader
                title={<Typography variant="h6"></Typography>}
                action={
                    <Stack spacing={1} direction={"row"}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<UploadIcon />}
                        >
                            Upload CSV
                            <VisuallyHiddenInput
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept=".csv"
                            />
                        </Button>
                        <Button variant="contained" startIcon={<DownloadIcon />}>
                            Download CSV
                        </Button>
                    </Stack>
                }
            />

            {(data?.filteringOptions || data?.sortingOptions) && (
                <>
                    <DataTableFilters
                        filters={data?.filteringOptions}
                        sorts={data?.sortingOptions}
                        initialSortDir={data?.sortingOptions[0]?.field}
                        onFilterChange={handleFilterUpdate}
                        onClearFilters={() => handleFilterUpdate({}, data?.sortingOptions[0]?.field)}
                    />
                    <Divider />
                </>
            )}
            {data?.items?.length > 0 ? (
                <DataTable columns={columns} rows={data?.items} />
            ) : (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                        No records found
                    </Typography>
                </Box>
            )}
            <DataTablePagination
                count={data?.totalItems || 0} // Total rows from API
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                onPaginationChange={handlePaginationChange}
            />
            <Dialog
                open={openUpload}
                onClose={() => {
                    handleReset();
                    setOpenUpload(false);
                    setIsUploadSuccessful("");
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Upload CSV File</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Selected file: <strong>{file?.name}</strong>
                    </Typography>
                    <Typography
                        variant="body1"
                        // color="success"
                        sx={{ color: "#15b79f" }}
                    >
                        {isUploadSuccessful}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleReset();
                            setOpenUpload(false);
                            setIsUploadSuccessful("");
                        }}
                    >
                        Close
                    </Button>
                    <Button onClick={UploadCsvFile} variant="contained">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TPOData;
