import React from "react";

export default function TermsAndConditionsPage() {
    return (
        <div>
            <h1>Terms & Conditions Page</h1>
            <p>
                This is a placeholder as the page is under construction. 
            </p>
        </div>
    );
}
