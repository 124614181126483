import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import BootstrapSelect from "../../components/BootstrapSelect";
import fetchData, { fetchPublicData } from "../../Helpers/apiCalls";
import settings from "../../settings/api";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CopyRight from "../../components/CopyRight";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import BootstrapInput from "../../components/BootstrapTextField";
import WarningIcon from "@mui/icons-material/Warning";

export const CustomCard = styled(MuiCard)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "100%",
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
        maxWidth: "550px",
    },
    boxShadow: "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
    ...theme.applyStyles("dark", {
        boxShadow: "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
    }),
}));

export const CustomContainer = styled(Stack)(({ theme }) => ({
    height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
    minHeight: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4),
    },
    "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        zIndex: -1,
        inset: 0,
        backgroundImage: "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
        backgroundRepeat: "no-repeat",
        ...theme.applyStyles("dark", {
            backgroundImage: "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
        }),
    },
}));

export default function AccountActivation() {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const { title, description } = location.state || {};
    const [logo] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme?.logoSmallUri);
    const [registerInfo, setRegisterInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        verifyEmail: "",
        localeId: 0,
        timeZoneId: 0,
        password: "",
        verifyPassword: "",
        acceptedTerms: false,
    });
    const [activate, setActivate] = useState({});
    const [errors, setErrors] = useState([]);
    const [activationRequired, setActivationRequired] = useState(true);
    const [submissonComplete, setSubmissonComplete] = useState(false);
    const [validatePassword, setValidatePassword] = useState("");

    useEffect(() => {
        fetchActivate();
        return () => {};
    }, []);

    // const fetchLocales = () => {
    //     const locales = JSON.parse(localStorage.getItem("userOrganizationInfo")).locales;
    //     setLocales(locales);
    // };

    const fetchActivate = async () => {
        const domain = getDomain();
        const subdomain = getSubdomain();
        fetch(settings.api().activate + `${location.search}`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
            },
        })
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (!data.success) {
                    setActivationRequired(false);
                    throw new Error(data);
                }
                setActivate(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleRegisterInfoChange = (e) => {
        const { name, value } = e.target;
        setRegisterInfo({
            ...registerInfo,
            [name]: value,
        });
        if (name === "password") {
            validatePasswordWithApi(value);
        }
    };

    const handleRegisterSubmit = async () => {
        let errorsTmp = [];
        if (
            registerInfo.firstName === "" ||
            registerInfo.lastName === "" ||
            registerInfo.email === "" ||
            registerInfo.verifyEmail === "" ||
            registerInfo.password === "" ||
            registerInfo.verifyPassword === "" ||
            registerInfo.localeId === 0
        ) {
            errorsTmp = [...errorsTmp, "Ensure all fields are filled."];
        }
        if (registerInfo.email !== registerInfo.verifyEmail) {
            errorsTmp = [...errorsTmp, "Ensure emails are the same."];
        }
        if (registerInfo.password !== registerInfo.verifyPassword) {
            errorsTmp = [...errorsTmp, "Ensure passwords are the same."];
        }
        if (!registerInfo.acceptedTerms) {
            errorsTmp = [...errorsTmp, "Terms and Conditions and Privacy Policy must be accepted."];
        }

        if (errorsTmp.length === 0) {
            const tmp = {
                userCode: searchParams.get("userCode"),
                validationCode: searchParams.get("validationCode"),
                firstName: registerInfo.firstName,
                lastName: registerInfo.lastName,
                email: registerInfo.email,
                password: registerInfo.password,
                localeId: registerInfo.localeId,
                acceptedTerms: registerInfo.acceptedTerms,
            };
            // console.log(JSON.stringify(tmp));
            const result = await fetchData(settings.api().activate + `${location.search}`, "PUT", tmp);
            console.log(result);

            if (result.success) {
                setSubmissonComplete(true);
                //     navigate("/notification");
            }
        } else {
            setErrors(errorsTmp);
        }
    };

    const validatePasswordWithApi = async (value) => {
        const result = await fetchPublicData(settings.api().validatePassword, "POST", value);
        if (result.success) {
            setValidatePassword("");
        } else {
            setValidatePassword(result.message);
        }
    };

    return (
        <CustomContainer>
            <CustomCard>
                <img src={logo} alt="Logo" style={{ width: "80px", display: "block", margin: "0 auto" }} />
                {activationRequired ? (
                    submissonComplete ? (
                        <CardContent>
                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                Submission complete. Please close this window and check your email.
                            </Typography>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Typography variant="h5" sx={{ width: "100%", textAlign: "center" }}>
                                {!!title ? title : "Account Activation"}
                            </Typography>
                            {!!description && (
                                <Typography variant="subtitle1" sx={{ width: "100%" }}>
                                    {description}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    flexDirection: "column",
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: { sm: "1fr 1fr" },
                                    gap: 2,
                                    pt: 2,
                                    pb: 1,
                                }}
                            >
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>First Name</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"firstName"}
                                        value={registerInfo.firstName}
                                        onChange={handleRegisterInfoChange}
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Last Name</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"lastName"}
                                        value={registerInfo.lastName}
                                        onChange={handleRegisterInfoChange}
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Email</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"email"}
                                        type="email"
                                        value={registerInfo.email}
                                        onChange={handleRegisterInfoChange}
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Verify Email</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"verifyEmail"}
                                        type="email"
                                        value={registerInfo.verifyEmail}
                                        onChange={handleRegisterInfoChange}
                                        error={
                                            registerInfo.verifyEmail !== "" &&
                                            registerInfo.verifyEmail !== registerInfo.email
                                        }
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Password</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"password"}
                                        type="password"
                                        value={registerInfo.password}
                                        onChange={handleRegisterInfoChange}
                                    />
                                </FormControl>
                                <FormControl variant="standard" fullWidth required={true}>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        <strong>Verify Password</strong>
                                    </InputLabel>
                                    <BootstrapInput
                                        name={"verifyPassword"}
                                        type="password"
                                        value={registerInfo.verifyPassword}
                                        onChange={handleRegisterInfoChange}
                                        error={
                                            registerInfo.verifyPassword !== "" &&
                                            registerInfo.verifyPassword !== registerInfo.password
                                        }
                                    />
                                </FormControl>
                                <FormControl fullWidth required>
                                    <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                        <strong>Language</strong>
                                    </InputLabel>
                                    <BootstrapSelect
                                        name="localeId"
                                        value={registerInfo?.localeId}
                                        onChange={handleRegisterInfoChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 300,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem disabled value={0}>
                                            <em>--Select Language--</em>
                                        </MenuItem>
                                        {activate?.locale?.values?.map((l) => (
                                            <MenuItem key={l.id} value={l.id}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                    </BootstrapSelect>
                                </FormControl>
                            </Box>
                            <FormControlLabel
                                sx={{ mb: 2 }}
                                slotProps={{ typography: { position: "relative", top: "10px" } }}
                                required
                                control={
                                    <Checkbox
                                        checked={registerInfo.acceptedTerms}
                                        onChange={() =>
                                            setRegisterInfo({
                                                ...registerInfo,
                                                acceptedTerms: !registerInfo.acceptedTerms,
                                            })
                                        }
                                    />
                                }
                                label={
                                    <>
                                        I have read and agree to the{' '}
                                        <Link to="/terms-and-conditions" target="__blank">Terms and Conditions</Link>{' '}
                                        and{' '}
                                        <Link to="/privacy-policy" target="__blank">Privacy Policy</Link>.
                                    </>
                                }
                            />

                            {errors.length > 0 &&
                                errors?.map((e, i) => (
                                    <Stack alignItems="center" direction="row" gap={1} key={i}>
                                        <WarningIcon color="error" />
                                        <Typography variant="body1" color="error">
                                            {e}
                                        </Typography>
                                    </Stack>
                                ))}

                            {!!validatePassword && (
                                <Stack alignItems="center" direction="row" gap={1}>
                                    <WarningIcon color="error" />
                                    <Typography variant="body1" color="error">
                                        {validatePassword}
                                    </Typography>
                                </Stack>
                            )}

                            <Button onClick={handleRegisterSubmit} fullWidth variant="contained" sx={{ mt: 2 }}>
                                Submit
                            </Button>
                        </CardContent>
                    )
                ) : (
                    <Box>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                            Error: Invalid link. Please login again.
                        </Typography>
                        <CardActions sx={{ justifyContent: "center" }}>
                            <Button onClick={() => navigate("/login")} variant="contained">
                                Login
                            </Button>
                        </CardActions>
                    </Box>
                )}
            </CustomCard>
            <CopyRight />
        </CustomContainer>
    );
}
