import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import fetchData from "../Helpers/apiCalls";
import settings from "../settings/api";
import dayjs from "dayjs";

const ActivityLogDisplay = ({ open, handleOpen, logId }) => {
    const [activity, setActivity] = useState({});
    // const [activityId, setActivityId] = useState("");

    useEffect(() => {
        // setActivityId(logId ?? "");
        if (!!logId) fetchActiviy(logId);
    }, [logId]);

    // useEffect(() => {
    //     return () => {};
    // }, []);

    const fetchActiviy = async (logId) => {
        // console.log(settings.api().activity + `/${logId}`);

        const result = await fetchData(settings.api().activity + `/${logId}`);
        // console.log(result);

        if (result.success) {
            setActivity(result.data);
        }
    };
    return (
        <Dialog open={open} onClose={() => handleOpen(false)}>
            <DialogTitle id="responsive-dialog-title">Log: {activity?.logId}</DialogTitle>
            <DialogContent>
                <p>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" color="textSecondary">
                            Editor
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            Created
                        </Typography>
                    </Box>

                    <Divider />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">
                            {activity?.userFirst} {activity?.userLast}
                        </Typography>
                        <Typography variant="body1">{dayjs(activity?.createdUtc).format("DD MMM, YYYY")}</Typography>
                    </Box>
                </p>
                <p>
                    <DialogContentText>Details</DialogContentText>
                    <Divider />
                    <Typography variant="body1">{activity?.details}</Typography>
                </p>
                <p>
                    <DialogContentText>Changes</DialogContentText>
                    <Divider />
                    <Typography variant="body1">{activity?.changes}</Typography>
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ActivityLogDisplay;
