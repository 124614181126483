import { useEffect, useState } from "react";
import settings from "../../settings/api";
import { useNavigate } from "react-router-dom";
import { getDomain, getSubdomain } from "../getSubdomain";
import fetchData from "../apiCalls";

export const useValidateDomainTenant = () => {
    const navigate = useNavigate();
    const [domains, setDomains] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const result = await fetchData(settings.api().domains, "GET");
                if (result.success) {
                    setDomains(result.data);
                }
                // const res = await fetch(settings.api().domains);
                // const data = await res.json();
                // // console.log(res, data);

                // setDomains(data.data);
            } catch (error) {
                console.error("Failed to fetch domains", error);
            } finally {
                setIsLoading(false); // Ensure loading state is updated here
            }
        };

        fetchDomains();
    }, []); // Empty dependency array ensures this only runs once after the component mounts
    // console.log(domains);

    useEffect(() => {
        if (!isLoading) {
            const subdomain = getSubdomain();
            const domain = getDomain();
            // console.log(domains);

            // Check if the domain-tenant combination is valid
            const isValidDomainTenant = domains.some((pair) => pair.domain === domain && pair.tenant === subdomain);

            if (!isValidDomainTenant) {
                navigate("/404");
            }
        }
    }, [domains, isLoading, navigate]); // Use location.search to track changes in the URL parameters
};
