import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import OrganizationInfoCard from "../../../components/Organizations/OrganizationInfoCard";
// import fetchData from "../../../Helpers/apiCalls";
// import settings from "../../../settings/api";
// import { useParams } from "react-router-dom";
import { Box, Card, Stack, Tab, Tabs } from "@mui/material";
import CustomTabPanel from "../../../components/CustomTabPanel";
import TPODeliveryDateSettings from "./TPODeliveryDateSettings";

export default function SystemAdminOrganization() {
    // const { t } = useTranslation();
    // const { id: organizationId } = useParams();
    // const [organization, setOrganization] = useState({});
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        // fetchOrganizations();

        return () => {};
    }, []);

    // const fetchOrganizations = async () => {
    //     const result = await fetchData(settings.api().globalOrganizations, "GET");
    //     if (result.success) {
    //         const org = result.data.items.find((item) => item.organizationId === organizationId);
    //         if (org) {
    //             const orgInfoResult = await fetchData(
    //                 settings.api().host + org.links.find((link) => link.rel === "self").href,
    //                 "GET"
    //             );
    //             if (orgInfoResult.success) {
    //                 setOrganization(orgInfoResult.data);
    //             }
    //         }
    //     }
    // };

    return (
        <Stack spacing={2}>
            <OrganizationInfoCard 
            // orgInfo={organization} 
            />
            <Card>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Overview" />
                        <Tab label="Tire Quote" />
                        <Tab label="TPO" />
                        <Tab label="Settings" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}></CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}></CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <TPODeliveryDateSettings />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={3}></CustomTabPanel>
            </Card>
        </Stack>
    );
}
