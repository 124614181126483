import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import settings from "../../../settings/api";
import { Link } from "react-router-dom";
// import { DataGrid } from "@mui/x-data-grid";
import RecursiveTable from "../../../components/RecursiveTable";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";
import CustomTabPanel from "../../../components/CustomTabPanel";
import fetchData from "../../../Helpers/apiCalls";

const headers = [
    {
        order: 1,
        title: "",
    },
    {
        order: 2,
        title: "Group Name",
    },
    {
        order: 3,
        title: "Level",
    },
    {
        order: 4,
        title: "Parent Group",
    },
    {
        order: 5,
        title: "Type",
    },
    {
        order: 6,
        title: "Last Modified",
    },
    {
        order: 7,
        title: "Action",
        align: "center",
    },
];

const Groups = () => {
    const [tabValue, setTabValue] = useState(0);
    const [groups, setGroups] = useState([]);
    const [pageContent, setPageContent] = useState([]);
    // console.log(pageContent);
    // const [groupTableHeaders, setGroupTableHeaders] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);

    const handleSelectChange = (id) => {
        if (id === selectedGroup) {
            setSelectedGroup(0);
        }
        setSelectedGroup(id);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        fetchContent();
        // setGroupTableHeaders(headers);
        fetchOrganizationGroups();

        return () => {};
    }, []);

    const fetchContent = async () => {
        const result = await fetchData(settings.api().content + "/1000", "GET");
        if (result.success) {
            setPageContent(result.data);
        }
    };

    const fetchOrganizationGroups = async () => {
        const result = await fetchData(settings.api().organizations + `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/groups`, "GET");
        if (result.success) {
            setGroups(result.data);
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                {pageContent.length > 0 && pageContent?.find((c) => c.contentId === 1)?.translation}
            </Typography>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between">
                        <Box sx={{ p: 1 }}>
                            <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ mr: 1 }}>
                                {pageContent?.find((c) => c.contentId === 2)?.translation}
                            </Button>
                            <Button startIcon={<FileUploadIcon />} variant="outlined">
                                {pageContent?.find((c) => c.contentId === 3)?.translation}
                            </Button>
                        </Box>
                        <Box sx={{ p: 1 }}>
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                component={Link}
                                to={`/admin/organizations/${
                                    JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                                }/groups/new`}
                            >
                                {pageContent?.find((c) => c.contentId === 6)?.translation}
                            </Button>
                        </Box>
                    </Stack>
                    {/* <Paper> */}
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab
                                label={`${pageContent?.find((c) => c.contentId === 4)?.translation}`}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={`${pageContent?.find((c) => c.contentId === 5)?.translation}`}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </Box>
                    {/* <Paper sx={{ mt: 2 }}> */}
                    <CustomTabPanel value={tabValue} index={0}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Search Groups"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <RecursiveTable
                            headers={headers}
                            data={groups}
                            selectedGroup={selectedGroup}
                            handleSelectChange={handleSelectChange}
                        />
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={tabValue} index={1}></CustomTabPanel> */}
                    {/* </Paper>  */}
                    {/* </Paper> */}
                </CardContent>
            </Card>
        </Box>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default Groups;
