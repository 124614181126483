import React, { useState } from "react";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { Avatar, Box, Button, Card, CardHeader, Chip, Divider, IconButton, Stack, Typography } from "@mui/material";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Users as UsersIcon } from "@phosphor-icons/react/dist/ssr/Users";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import fetchData from "../../../Helpers/apiCalls";
import settings from "../../../settings/api";
import { useEffect } from "react";
import { DataTable } from "../../../components/DataTable/DataTable";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
// import * as P from "@phosphor-icons/react";

const columns = [
    {
        formatter: (row) => (
            <div>
                <Typography color="text.primary" variant="subtitle2">
                    {row.firstName} {row.lastName}
                </Typography>

                <Typography color="text.secondary" variant="body2">
                    {row.email}
                </Typography>
            </div>
        ),
        name: "Name",
        width: "100px",
    },
    {
        formatter: (row) => {
            const mapping = {
                Active: { label: "Active", color: "#15b79f" },
                Disabled: { label: "Disabled", color: "secondary" },
                Pending: { label: "Pending", color: "#f04438" },
                Locked: { label: "Locked", color: "#f04438" },
                Deleted: { label: "Deleted", color: "#f04438" },
            };
            const { label, color, icon } = mapping[row.userStatus] ?? { label: "Unknown", icon: null, color: "secondary" };
            // const PIcon = P[icon];
            return (
                <Chip
                    label={label}
                    // label={row.status.label}
                    sx={{ bgcolor: color, color: "#FFF", borderColor: color }}
                    // sx={{ bgcolor: row.status.color, color: "#FFF", borderColor: row.status.color }}
                    size="small"
                    variant="outlined"
                    // icon={<PIcon color={"#FFF"} weight="fill" />}
                />
            );
        },
        name: "Status",
        width: "100px",
        align: "center",
    },
    { field: "siteName", name: "Site Name", width: "140px" },
    { field: "userRole", name: "Role", width: "70px" },
    { field: "localeName", name: "Locale", width: "110px" },
    {
        formatter(row) {
            const date = dayjs(row.modifiedUtc).format("MMM D, YYYY");
            return date !== "Invalid Date" ? date : "N/A";
        },
        name: "Modified",
        width: "100px",
    },{
        formatter(row) {
            const date = dayjs(row.createUtc).format("MMM D, YYYY");
            return date !== "Invalid Date" ? date : "N/A";
        },
        name: "Created",
        width: "100px",
    },
    {
        formatter: (row) => {
            return (
                <IconButton component={RouterLink} to={row.pageUrl}>
                    <EyeIcon />
                </IconButton>
            );
        },
        name: "View",
        hideName: true,
        width: "100px",
        align: "center",
    },
];
export default function PortalUsersList() {
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const location = useLocation();

    useEffect(() => {
        fetchPortalUser(location.search);

        return () => {};
    }, [location.search]);

    const fetchPortalUser = async (filters = "") => {
        const url = filters !== "" ? settings.api(id).portalAdminUsers + filters : settings.api(id).portalAdminUsers;
        const result = await fetchData(url, "GET");
        // console.log(result);

        if (result.success) {
            setUsers(result.data);
        }
    };
    return (
        <div>
            {/* <h1>Organization Users List</h1>
            <p>
                Lists all of the users in an organization across all sites that the portal admin has access to. For
                example, a regional manager can quickly look up a user by name, email or phone number for any user
                belonging to a site that he/she has access to. This allows an admin to quickly find users on a call
                without the need for searching through sites.
            </p> */}
            <Card>
                <CardHeader
                    title={"Users"}
                    avatar={
                        <Avatar>
                            <UsersIcon fontSize="large" />
                        </Avatar>
                    }
                    action={
                        <Button
                            sx={{ mt: 1 }}
                            variant="contained"
                            startIcon={<PlusIcon />}
                            size="small"
                            // component={RouterLink}
                            // to={"/admin/global/tire-quote/tpo/schedules/add"}
                        >
                            Create
                        </Button>
                    }
                />
                {(users?.filteringOptions?.length > 0 || users?.sortingOptions?.length > 0) && (
                    <>
                        <DataTableFilters filters={users?.filteringOptions} sorts={users?.sortingOptions} />
                        <Divider />
                    </>
                )}
                {users?.items?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        // columns={[]}
                        rows={users?.items}
                    />
                ) : (
                    <Box sx={{ p: 3 }}>
                        <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                            No records found
                        </Typography>
                    </Box>
                )}

                <DataTablePagination
                    count={users?.totalItems}
                    pageSize={users?.pageSize}
                    pageNumber={users?.pageNumber}
                />
            </Card>
        </div>
    );
}
