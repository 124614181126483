import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Avatar, Card, CardContent, CardHeader, Divider, Switch } from "@mui/material";
import { EnvelopeSimple as EnvelopeSimpleIcon } from "@phosphor-icons/react/dist/ssr/EnvelopeSimple";
import { Phone as PhoneIcon } from "@phosphor-icons/react/dist/ssr/Phone";
import AccountLayout from "./Account";

export const metadata = { title: `Notifications | Settings` };
const navItems = [
    {
        key: "personal",
        title: "Personal",
        items: [
            { key: "account", title: "Account", href: "/portal/account", icon: "user-circle" },
            { key: "notifications", title: "Notifications", href: "/portal/notifications", icon: "bell" },
            { key: "security", title: "Security", href: "", icon: "lock-key" },
        ],
    },
    {
        key: "organization",
        title: "Organization",
        items: [
            { key: "billing", title: "Billing & plans", href: "", icon: "credit-card" },
            { key: "team", title: "Team", href: "", icon: "users-three" },
            {
                key: "integrations",
                title: "Integrations",
                href: "",
                icon: "plugs-connected",
            },
        ],
    },
];

export default function Notifications() {
    return (
        <AccountLayout navItems={navItems}>
            <Stack spacing={4}>
                <div>
                    <Typography variant="h4">Notifications</Typography>
                </div>
                <Stack spacing={4}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <EnvelopeSimpleIcon fontSize="var(--Icon-fontSize)" />
                                </Avatar>
                            }
                            title="Email"
                        />
                        <CardContent>
                            <Stack divider={<Divider />} spacing={3}>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
                                >
                                    <Stack spacing={1}>
                                        <Typography variant="subtitle1">Product updates</Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            News, announcements, and product updates.
                                        </Typography>
                                    </Stack>
                                    <Switch defaultChecked />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
                                >
                                    <Stack spacing={1}>
                                        <Typography variant="subtitle1">Security updates</Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            Important notifications about your account security.
                                        </Typography>
                                    </Stack>
                                    <Switch />
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <PhoneIcon fontSize="var(--Icon-fontSize)" />
                                </Avatar>
                            }
                            title="Phone"
                        />
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
                            >
                                <Stack spacing={1}>
                                    <Typography variant="subtitle1">Security updates</Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        Important notifications about your account security.
                                    </Typography>
                                </Stack>
                                <Switch />
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
        </AccountLayout>
    );
}
