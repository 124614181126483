import React from "react";
import { Avatar, Box, Card, CardHeader, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import CustomTabPanel from "../../../components/CustomTabPanel";
import SettingsIcon from "@mui/icons-material/Settings";

export default function SystemTireQuoteSettings() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <Card>
                <CardHeader
                    title={<Typography variant="h6">Settings</Typography>}
                    avatar={
                        <Avatar>
                            <SettingsIcon />
                        </Avatar>
                    }
                />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="General" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}></CustomTabPanel>
            </Card>
        </div>
    );
}
