import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import AssessmentIcon from "@mui/icons-material/Assessment";
import { UsersList } from "../../../components/Users/UsersList";
import { Link } from "react-router-dom";
// import { Conversions } from "../../components/ConversionChart";
// import { TopProducts } from "../../components/TopSellingProductCard";
import settings from "../../../settings/api";
import formatPhone from "../../../Helpers/PhoneFormatter";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FaxIcon from "@mui/icons-material/Fax";
import StoreIcon from "@mui/icons-material/Store";
import formatPostalCode from "../../../Helpers/PostalCodeFormatter";
import VerifiedIcon from "@mui/icons-material/Verified";
// import Settings from "../../components/MySite/Settings";
import ReactApexChart from "react-apexcharts";
import { dayjs } from "../../../Helpers/dayjs";
import Activity from "./Activity";
import { useTranslation } from "react-i18next";
import fetchData from "../../../Helpers/apiCalls";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ marginTop: "20px" }}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}
const users = [
    {
        id: "MSG-001",
        content: "Shop Technian",
        author: { name: "Alcides Antonio", avatar: "/assets/avatar-10.png", status: "online" },
        createdAt: dayjs().subtract(2, "minute").toDate(),
    },
    {
        id: "MSG-002",
        content: "Sales Manager",
        author: { name: "Marcus Finn", avatar: "/assets/avatar-9.png", status: "offline" },
        createdAt: dayjs().subtract(56, "minute").toDate(),
    },
    {
        id: "MSG-003",
        content: "Sales Representative",
        author: { name: "Carson Darrin", avatar: "/assets/avatar-3.png", status: "online" },
        createdAt: dayjs().subtract(3, "hour").subtract(23, "minute").toDate(),
    },
    {
        id: "MSG-004",
        content: "Sales Representative",
        author: { name: "Fran Perez", avatar: "/assets/avatar-5.png", status: "online" },
        createdAt: dayjs().subtract(8, "hour").subtract(6, "minute").toDate(),
    },
    {
        id: "MSG-005",
        content: "Administator",
        author: { name: "Jie Yan", avatar: "/assets/avatar-8.png", status: "offline" },
        createdAt: dayjs().subtract(10, "hour").subtract(18, "minute").toDate(),
    },
];

export default function MySite(props) {
    const { t } = useTranslation();
    const [site, setSite] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [userLoginSummaryData, setUserLoginSummaryData] = useState({});
    const [userLoginSummaryChartData, setUserLoginSummaryChartData] = useState({ series: [], options: {} });
    const [recentUsers, setRecentUsers] = useState([]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        fetchMeSiteInfo();
        fetchRecentUsers();

        return () => {};
    }, []);

    const fetchMeSiteInfo = async () => {
        const result = await fetchData(settings.api().meSite, "GET");
        if (result.success) {
            setSite(result.data);
        }
    };
    useEffect(() => {
        if (site.id) {
            fetchUsersSigninData();
        }

        return () => {};
    }, [site]);

    const fetchUsersSigninData = async () => {
        const result = await fetchData(settings.api(site?.id).loginSummary + "?Summary=true", "GET");

        if (result.success) {
            setUserLoginSummaryData(result.data);
            const tmp = {
                series: [
                    {
                        name: result.data.dataLabel,
                        // data: result.data.dataPoints.map((d) => ({ x: d.xValue, y: d.yValue })),
                        // data: result.data.dataPoints.map((d, i) => ({ x: i+1, y: d.yValue })),

                        // react-apexcharts datetime type uses timestamp, so we need to convert date string from api to timestamp
                        data: result.data.dataPoints.map((d) => ({ x: new Date(d.xValue).getTime(), y: d.yValue })),
                        // data: result.data.dataPoints.map((d) => ({ x: `${d.xValue}T00:00:00Z`, y: d.yValue })),
                    },
                ],
                options: {
                    chart: {
                        type: "line",
                        parentHeightOffset: 0,
                        toolbar: {
                            tools: {
                                download: true,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false, // Data labels directly on the chart are disabled
                    },
                    stroke: {
                        curve: "smooth", // Smooth curve for the line
                    },
                    xaxis: {
                        type: "datetime",
                        tickAmount: result.data.dataPoints.length,
                        labels: {
                            maxHeight: 80,
                            formatter: (value) => {
                                return dayjs(value).format('MMM DD, YYYY');
                            },
                        },
                        title: {
                            text: result.data.xAxisLabel,
                            style: {
                                fontSize: "14px",
                                fontWeight: "normal",
                            },
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: (val) => val.toLocaleString(), // Format numbers with commas
                        },
                        title: {
                            text: result.data.yAxisLabel,
                            style: {
                                fontSize: "14px",
                                fontWeight: "normal",
                            },
                        },
                    },
                    tooltip: {
                        x: {
                            // format: formatTooltipX,
                            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                // console.log(dataPointIndex);

                                const point = result.data.dataPoints[dataPointIndex];
                                // console.log(result.data.dataPoints[dataPointIndex]);

                                return point.dataLabel;
                            },
                        },
                        y: {
                            format: (val) => val,
                        },
                    },
                    markers: {
                        size: 5,
                    },
                },
            };
            setUserLoginSummaryChartData(tmp)
        }
    };    
    
    const fetchRecentUsers = async () => {
        const result = await fetchData(settings.api(site?.id).recentUsers, "GET");
        if (result.success) {
            setRecentUsers(result.data);
        }
    };

    return (
        <>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="space-between" column={{ xs: 12, md: 4 }}>
                                <Grid item>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <img
                                            src={
                                                JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme
                                                    ?.logoSmallUri
                                            }
                                            alt="Logo"
                                            style={{ marginRight: "8px" }} // optional: add spacing between image and text
                                        />
                                        {site.name ? site.name : "Some Site Name"}
                                    </Typography>
                                </Grid>
                                <Grid item align="center">
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            // className={classes.button}
                                            sx={{ margin: 1, width: "120px" }}
                                            startIcon={<EditIcon />}
                                            // onClick={() => setEditSiteOpen(true)}
                                            component={Link}
                                            to="/portal/site/edit"
                                        >
                                            {t("base.button_edit", "Edit")}
                                        </Button>
                                        {/* <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ margin: 1, width: "120px" }}
                                                startIcon={<AssessmentIcon />}
                                            >
                                                Report
                                            </Button> */}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item xs>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Typography
                                                    variant="h5"
                                                    component="div"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    {site?.physicalAddress?.verified && (
                                                        <VerifiedIcon color="success" sx={{ mr: 1 }} />
                                                    )}
                                                    {site?.physicalAddress?.address1}
                                                </Typography>
                                                <Typography variant="h5">
                                                    {site?.physicalAddress?.city ? site?.physicalAddress?.city : ""},{" "}
                                                    {site?.physicalAddress?.provinceCode
                                                        ? site?.physicalAddress?.provinceCode
                                                        : ""}{" "}
                                                    {site?.physicalAddress?.postal
                                                        ? formatPostalCode(site?.physicalAddress?.postal)
                                                        : ""}
                                                </Typography>
                                                {site?.code && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <StoreIcon
                                                            fontSize="small"
                                                            style={{ verticalAlign: "middle" }}
                                                        />{" "}
                                                        {site?.code}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    color="inherit"
                                                    sx={{ whiteSpace: "nowrap" }}
                                                    variant="subtitle2"
                                                >
                                                    <PhoneIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                                    {formatPhone(site?.phonePrimary)}
                                                </Typography>
                                                {site.phoneSecondary && (
                                                    <Typography variant="body1">
                                                        Tel 2: {site.phoneSecondary}
                                                    </Typography>
                                                )}
                                                {site?.fax && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <FaxIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                                        {site?.fax}
                                                    </Typography>
                                                )}
                                                {site?.email && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <MailOutlineIcon
                                                            fontSize="small"
                                                            style={{ verticalAlign: "middle" }}
                                                        />{" "}
                                                        {site?.email}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="caption">
                                                    {t("base.userView_site_parentOrganization", "Parent Organization")}:
                                                </Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="h6" display={"inline"}>
                                                        {site?.organization?.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        display={"inline"}
                                                        sx={{ fontSize: "0.8rem", fontWeight: 400 }}
                                                    >
                                                        {" "}
                                                        ({site?.organization?.organizationType})
                                                    </Typography>
                                                </Box>
                                                <Typography variant="caption">
                                                    {t("base.userView_site_siteType", "Site Type")}:
                                                </Typography>
                                                <Typography variant="subtitle1">{site?.siteType}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box>
                                                    <Typography variant="caption">
                                                        {t(
                                                            "base.userView_site_servicesAvailable",
                                                            "Services Available"
                                                        )}
                                                        :
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexWrap: "wrap", // Ensures the chips wrap under the label
                                                            gap: "8px", // Space between the chips
                                                        }}
                                                    >
                                                        {site?.services?.map((s) => (
                                                            <Link key={s.name}>
                                                                <Chip
                                                                    sx={{
                                                                        borderRadius: "2px",
                                                                    }}
                                                                    label={s.name}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            </Link>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Grid item align="center">
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                // className={classes.button}
                                                sx={{ margin: 1, width: "120px" }}
                                                startIcon={<EditIcon />}
                                                // onClick={() => setEditSiteOpen(true)}
                                                component={Link}
                                                to="/portal/my-site/edit"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ margin: 1, width: "120px" }}
                                                startIcon={<AssessmentIcon />}
                                            >
                                                Report
                                            </Button>
                                        </div>
                                    </Grid> */}
                            </Grid>
                        </CardContent>

                        {/* <CreateEditSite
                    open={editSiteOpen}
                    handleClose={() => setEditSiteOpen(false)}
                    data={{ title: `${t("site.site_form.edit_site")}`, type: "edit" }}
                /> */}
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label={t("base.tab_overview", "Overview")} />
                                <Tab label={t("base.tab_users", "Users")} />
                                <Tab label={t("base.tab_activity", "Activity")} />
                                {/* <Tab label={t("base.tab_settings", "Settings")} /> */}

                                {/* <Tab label="OnDemand" />
                                    <Tab label="CARSability" />
                                    <Tab label="TAC" /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item md={8} xs={12}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h6">{userLoginSummaryData?.title}</Typography>
                                            <div id="chart">
                                                <ReactApexChart
                                                    options={userLoginSummaryChartData?.options}
                                                    series={userLoginSummaryChartData?.series}
                                                    height={350}
                                                    // type={userLoginSummaryChartData?.options?.chart?.type || "line"}
                                                    type="line"
                                                />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h6">Recent Users</Typography>
                                            <List
                                                disablePadding
                                                sx={{
                                                    p: 1,
                                                    "& .MuiListItemButton-root": { borderRadius: 1 },
                                                    "& .MuiBadge-dot": {
                                                        border: "2px solid var(--mui-palette-background-paper)",
                                                        borderRadius: "50%",
                                                        bottom: "5px",
                                                        height: "12px",
                                                        right: "5px",
                                                        width: "12px",
                                                    },
                                                }}
                                                subheader={
                                                    <ListSubheader
                                                        disableGutters
                                                        sx={{
                                                            fontWeight: "bold",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <span>Name</span>
                                                        <span>Last sign-on</span>
                                                    </ListSubheader>
                                                }
                                            >
                                                {recentUsers.map((u, index) => (
                                                    <React.Fragment key={index}>
                                                        <ListItem disablePadding>
                                                            <ListItemButton sx={{ p: 0 }}>
                                                                <ListItemText
                                                                    disableTypography
                                                                    primary={
                                                                        <Typography noWrap variant="subtitle2">
                                                                            {u.firstName} {u.lastName}
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            color="text.secondary"
                                                                            noWrap
                                                                            variant="body2"
                                                                        >
                                                                            {u.login}
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <Typography
                                                                    color="text.secondary"
                                                                    sx={{ whiteSpace: "nowrap" }}
                                                                    variant="caption"
                                                                >
                                                                    {u.dateTimeString}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </ListItem>
                                                        {index < users.length - 1 && <Divider />}
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <UsersList />
                        </CustomTabPanel>

                        <CustomTabPanel value={tabValue} index={2}>
                            <Activity />
                        </CustomTabPanel>
                        {/* <CustomTabPanel value={tabValue} index={3}>
                            <Settings />
                        </CustomTabPanel> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
