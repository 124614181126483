import React from "react";
import { Avatar, Card, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTableFilters } from "../../../../components/DataTable/DataTableFilters";
import { Tire as TireIcon } from "@phosphor-icons/react/dist/ssr/Tire";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";

const columns = [
    { field: "partNo", name: "Part #", width: "60px" },
    { field: "brand", name: "Brand", width: "60px" },
    { field: "productName", name: "Product Name", width: "100px" },
    { field: "size", name: "Size", width: "60px" },
    { field: "loadSpeed", name: "Load Speed", width: "70px" },
    { field: "type", name: "Type", width: "40px" },
    { formatter: (row) => <Typography>${row.bookedPrice.toFixed(2)}</Typography>, name: "Booked Price", width: "50px" },
    { field: "previousBooked", name: "Prev. Booked", width: "80px" },
    { field: "previousPurchased", name: "Prev. Purchased", width: "80px" },
    { formatter: (row) => <TextField size="small" value={row.totalQty} />, name: "Total Qty.", width: "70px" },
];
const rows = [
    {
        partNo: "137862",
        brand: "Michelin",
        productName: "X-Ice",
        size: "225/60R17",
        loadSpeed: "99H",
        type: "W",
        bookedPrice: 125.8,
        previousBooked: 80,
        previousPurchased: 96,
        totalQty: 0,
    },
    {
        partNo: "37846",
        brand: "Michelin",
        productName: "Latitude X-Ice",
        size: "235/65R17",
        loadSpeed: "108T",
        type: "W",
        bookedPrice: 140.33,
        previousBooked: 80,
        previousPurchased: 100,
        totalQty: 0,
    },
    {
        partNo: "137862",
        brand: "Michelin",
        productName: "X-Ice",
        size: "225/60R17",
        loadSpeed: "99H",
        type: "W",
        bookedPrice: 125.8,
        previousBooked: 80,
        previousPurchased: 96,
        totalQty: 0,
    },
    {
        partNo: "37846",
        brand: "Michelin",
        productName: "Latitude X-Ice",
        size: "235/65R17",
        loadSpeed: "108T",
        type: "W",
        bookedPrice: 140.33,
        previousBooked: 80,
        previousPurchased: 100,
        totalQty: 0,
    },
    {
        partNo: "137862",
        brand: "Michelin",
        productName: "X-Ice",
        size: "225/60R17",
        loadSpeed: "99H",
        type: "W",
        bookedPrice: 125.8,
        previousBooked: 80,
        previousPurchased: 96,
        totalQty: 0,
    },
    {
        partNo: "37846",
        brand: "Michelin",
        productName: "Latitude X-Ice",
        size: "235/65R17",
        loadSpeed: "108T",
        type: "W",
        bookedPrice: 140.33,
        previousBooked: 80,
        previousPurchased: 100,
        totalQty: 0,
    },
];

const data = {
    filteringOptions: [
        {
            field: "brand",
            label: "Brand",
            labelDescription: "Filter by brand",
            fieldType: "dropdown",            
            valuesSource: "static",
            values: [
                { id: 1, name: "Michelin" },
                { id: 2, name: "Perelli" },
                { id: 3, name: "Bridgestone" },
                { id: 4, name: "Continental" },
            ],
        },
        {
            field: "season",
            label: "Season",
            labelDescription: "Filter by season",
            fieldType: "dropdown",
            valuesSource: "static",
            values: [
                { id: 1, name: "Winter" },
                { id: 2, name: "Summer" },
                { id: 2, name: "All Seasons" },
            ],
        },

        {
            field: "size",
            label: "Size",
            labelDescription: "Filter by product name",
            fieldType: "text",
            valuesSource: "",
        },
        {
            field: "model",
            label: "Model",
            labelDescription: "Filter by model",
            fieldType: "text",
            valuesSource: "",
        },
        {
            field: "partNo",
            label: "Part No.",
            labelDescription: "Filter by part no.",
            fieldType: "text",
            valuesSource: "",
        },
    ],
    sortingOptions: [
        {
            field: "brand",
            label: "Brand",
            order: ["asc", "desc"],
        },
        {
            field: "previouslyPurchased",
            label: "Prev. Purchased",
            order: ["asc", "desc"],
        },
    ],
    totalItems: 6,
    pageNumber: 1,
    pageSize: 25,
};

export default function OrderQuantities() {
    return (
        <Card>
            <CardHeader
                title={<Typography variant="h6">Order Tire Quantities</Typography>}
                avatar={
                    <Avatar>
                        <TireIcon size={24} />
                    </Avatar>
                }
            />
            <CardContent>
                <DataTableFilters
                    filters={data?.filteringOptions}
                    sorts={data?.sortingOptions}
                    initialSortDir={data?.sortingOptions[0]?.field}
                />
                <Divider sx={{ mb: 2 }} />
                <DataTable dense={true} columns={columns} rows={rows} />
                <DataTablePagination count={data?.totalItems} pageNumber={data?.pageNumber} pageSize={data?.pageSize} />
            </CardContent>
        </Card>
    );
}
