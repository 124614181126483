import React, { useEffect, useState } from "react";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
import { Box, Button, CardHeader, Chip, Divider, IconButton, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTableFilters } from "../../../../components/DataTable/DataTableFilters";
// import fetchData from "../../../../Helpers/apiCalls";
// import settings from "../../../../settings/api";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";

const columns = [
    {
        formatter: (row) => (
            <div>
                <Typography color="text.primary" variant="subtitle2">
                    {row.siteName}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                    {row.address}
                </Typography>
            </div>
        ),
        field: "siteName",
        name: "Site Name",
        width: "140px",
        // width: "25%"
    },
    {
        formatter: (row) => {
            const mapping = {
                Upcoming: { label: "Upcoming", color: "warning" },
                Active: { label: "Active", color: "success" },
                Closed: { label: "Closed", color: "error" },
            };
            const { label, color } = mapping[row.status] ?? { label: "Unknown", color: "secondary" };

            return <Chip color={color} label={label} size="small" variant="soft" />;
        },
        name: "Status",
        width: "100px",
        align: "center",
    },
    { field: "totalQuantity", name: "Total Quantity", width: "80px", align: "center" },
    {
        formatter: (row) => <Typography variant="inherit">{dayjs(row.dateCompleted).format("MMM D, YYYY")}</Typography>,
        name: "Date Completed",
        width: "100px",
        align: "center",
    },
    {
        formatter: (row) =>
            row.downloadAvailable ? (
                <IconButton>
                    <DownloadIcon />
                </IconButton>
            ) : null,
        name: "Download CSV",
        width: "100px",
        align: "center",
    },
];

const TPOScheduleBookings = () => {
    // const { id } = useParams();
    // const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        // fetchTPOScheduleBookings();
        setBookings({
            items: [
                {
                    siteName: "Site A",
                    address: "123 Some Street, City, ON, A1B 2C3",
                    status: "Active",
                    totalQuantity: 135,
                    dateCompleted: "2024-12-15T05:00:00",
                    downloadAvailable: true,
                },
                {
                    siteName: "Site B",
                    address: "456 That Street, City, ON, A1B 2C3",
                    status: "Closed",
                    totalQuantity: 120,
                    dateCompleted: "2024-12-12T05:00:00",
                    downloadAvailable: true,
                },
                {
                    siteName: "Site C",
                    address: "789 This Street, City, ON, A1B 2C3",
                    status: "Closed",
                    totalQuantity: 92,
                    dateCompleted: "2024-12-01T05:00:00",
                    downloadAvailable: true,
                },
            ],
            filteringOptions: [
                {
                    field: "title",
                    label: "Title",
                    labelDescription: "Filter by title",
                    fieldType: "text",
                    valuesSource: "",
                },
                {
                    field: "statusId",
                    label: "Status",
                    labelDescription: "Status tabs",
                    fieldType: "tab",
                    valuesSource: "static",
                    values: [
                        {
                            id: 1,
                            name: "All",
                        },
                        {
                            id: 2,
                            name: "Active",
                        },
                        {
                            id: 3,
                            name: "Upcoming",
                        },
                        {
                            id: 4,
                            name: "Closed",
                        },
                    ],
                },
            ],
            sortingOptions: [
                {
                    field: "date",
                    label: "Date",
                    order: ["asc", "desc"],
                },
            ],
            totalItems: 3,
            pageNumber: 1,
            pageSize: 25,
        });

        return () => {};
    }, []);

    // const fetchTPOScheduleBookings = async () => {
    // const result = await fetchData(settings.api(id).tpoScheduleActivity);
    // console.log(result);
    // if (result.success) {
    //     setActivities(result.data);
    // }
    // };

    return (
        <div>
            <CardHeader
                title={<Typography variant="h6">Site Bookings</Typography>}
                action={
                    <Button variant="contained" startIcon={<DownloadIcon />}>
                        Download All CSV
                    </Button>
                }
            />
            {(bookings?.filteringOptions || bookings?.sortingOptions) && (
                <>
                    <DataTableFilters
                        filters={bookings?.filteringOptions}
                        sorts={bookings?.sortingOptions}
                        initialSortDir={bookings?.sortingOptions[0]?.field}
                    />
                    <Divider />
                </>
            )}
            {bookings?.items?.length > 0 ? (
                <DataTable columns={columns} rows={bookings?.items} />
            ) : (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                        No records found
                    </Typography>
                </Box>
            )}

            <DataTablePagination
                count={bookings?.totalItems}
                pageNumber={bookings?.pageNumber}
                pageSize={bookings?.pageSize}
            />
        </div>
    );
};

export default TPOScheduleBookings;
