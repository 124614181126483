import React from "react";
import CopyRight from "../../components/CopyRight";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import MiniDrawer from "../Drawer";

import { I18nextProvider } from "react-i18next";
import i18n, { loadTranslations } from "../../locales/i18n";
const PortalLayout = (props) => {
    useEffect(() => {
        const userLocale = JSON.parse(localStorage.getItem("user"))?.localeId;
        const languageCode = JSON.parse(localStorage.getItem("userOrganizationInfo"))?.locales?.find(
            (l) => l.id === userLocale
        )?.code;

        loadTranslations(languageCode);

        return () => {};
    }, []);

    return (
        <I18nextProvider i18n={i18n}>
            <Box>
                <MiniDrawer>
                    <Box
                        sx={{
                            // 304.44px is the additional of all the padding and margin above main content + height of copyright component
                            minHeight: "calc(100vh - 304.44px)",
                        }}
                    >
                        <Outlet />
                    </Box>
                    <CopyRight />
                </MiniDrawer>
            </Box>
        </I18nextProvider>
    );
};
export default PortalLayout;
// export default Portal;
