import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
export default function CopyRight({ copyrightName, copyrightUrl }) {
    const [copyright, setCopyright] = useState({ copyrightName, copyrightUrl });

    useEffect(() => {
        const tmp = JSON.parse(localStorage.getItem("userOrganizationInfo"));

        if (!!tmp) {
            setCopyright({
                copyrightName: tmp?.copyrightName,
                copyrightUrl: tmp?.copyrightUrl,
            });
        }

        return () => {};
    }, []);

    return (
        <div style={{ marginTop: "20px" }}>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                {new Date().getFullYear()}{" "}
                {/* <Link target="_blank" color="inherit" href={copyright?.copyrightUrl}>
                    {copyright?.copyrightName}
                </Link> */}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit" }}
                    href={copyright?.copyrightUrl}
                >
                    {copyright?.copyrightName}
                </a>
                <br />
                {"Created By "}
                {/* <Link target="__blank" color="inherit" href="https://saphera.com">
                    Saphera Software
                </Link> */}
                <a target="_blank" rel="noopener noreferrer" style={{ color: "inherit" }} href="https://saphera.com">
                    Saphera Software
                </a>
            </Typography>
        </div>
    );
}
