import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
// import { useCustomersSelection } from "./customers-selection-context";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterButton, FilterPopover, useFilterContext } from "./FilterButton";
import { Option } from "../option";
import { Box, InputLabel, MenuItem } from "@mui/material";
import fetchData from "../../Helpers/apiCalls";
import settings from "../../settings/api";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export function DataTableFilters({
    filters = [],
    initialSortDir = "",
    sorts = [],
    onFilterChange,
    onClearFilters
    // pageNumber, pageSize
}) {
    // console.log(onFilterChange);

    const { t } = useTranslation();
    const location = useLocation();
    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    // const selection = useCustomersSelection();
    const [filterValues, setFilterValues] = useState({});
    const [sortDir, setSortDir] = useState(initialSortDir);
    const [hasFilters, setHasFilters] = useState(false);
    const [tabs, setTabs] = useState({});

    useEffect(() => {
        createInitialFilterState();
        return () => {};
    }, []);

    const createInitialFilterState = () => {
        // Parse the search parameters from the URL on component load
        const searchParams = new URLSearchParams(location.search);

        // Dynamically create the initialFilters based on available fields in `filters`
        let initialFilters = filters
            .filter((f) => f.fieldType !== "tab")
            .reduce((acc, filter) => {
                const value = searchParams.get(filter.field);
                acc[filter.field] = value === null ? "" : isNaN(value) ? value : Number(value);
                return acc;
            }, {});

        const t = filters?.find((f) => f.fieldType === "tab");
        if (t) {
            setTabs(t);
            initialFilters = {
                ...initialFilters,
                status: !!searchParams.get("statusId") ? parseInt(searchParams.get("statusId")) : t.values[0].id,
            };
        }

        setFilterValues(initialFilters);

        const initialSortDir = searchParams.get("sortDir") || sorts[0]?.field;
        setSortDir(initialSortDir);
    };

    // const updateSearchParams = React.useCallback(
    //     (newFilters, newSortDir) => {
    //         const searchParams = new URLSearchParams();

    //         // Add dynamic filters to the URL search parameters
    //         Object.entries(newFilters).forEach(([key, value]) => {
    //             if (value) searchParams.set(key, value);
    //         });
    //         // console.log(pageNumber, pageSize);

    //         searchParams.set("pageNumber", pageNumber);
    //         searchParams.set("pageSize", pageSize);

    //         // Add sortDir if provided
    //         if (newSortDir) {
    //             searchParams.set("sortDir", newSortDir);
    //         }

    //         navigate(`${location.pathname}?${searchParams.toString()}`);
    //     },
    //     [navigate, location.pathname]
    // );

    const handleClearFilters = React.useCallback(() => {
        // updateSearchParams({}, sortDir);
        onFilterChange({}, sortDir);

        // Dynamically create the initialFilters based on available fields in `filters`
        let initialFilters = filters
            .filter((f) => f.fieldType !== "tab")
            .reduce((acc, filter) => {
                acc[filter.field] = "";
                return acc;
            }, {});

        const t = filters?.find((f) => f.fieldType === "tab");
        if (t) {
            setTabs(t);
            initialFilters = {
                ...initialFilters,
                status: t.values[0].id,
            };
        }

        setFilterValues(initialFilters);
        const initialSortDir = sorts[0]?.field;
        setSortDir(initialSortDir);
    }, [
        // updateSearchParams
        onFilterChange,
        sortDir,
    ]);

    const handleTabChange = React.useCallback(
        (_, value) => {
            setFilterValues({ ...filterValues, status: value });
            // updateSearchParams({ ...filterValues, status: value }, sortDir);
            onFilterChange({ ...filterValues, status: value }, sortDir);
        },
        [
            // updateSearchParams,
            onFilterChange,
            filterValues,
            sortDir,
        ]
    );

    const handleFilterChange = React.useCallback(
        (name, value) => {
            setFilterValues({ ...filterValues, [name]: value });
            // updateSearchParams({ ...filterValues, [name]: value }, sortDir);
            onFilterChange({ ...filterValues, [name]: value }, sortDir);
        },
        [
            // updateSearchParams
            onFilterChange,
            filterValues,
            sortDir,
        ]
    );

    const handleSortChange = React.useCallback(
        (event) => {
            const newSortDir = event.target.value;
            setSortDir(newSortDir);
            // updateSearchParams(filterValues, newSortDir);
            onFilterChange(filterValues, newSortDir);
        },
        [
            // updateSearchParams,
            onFilterChange,
            filterValues,
        ]
    );

    useEffect(() => {
        const result = areAllPropertiesEmptyExceptStatusId(filterValues);
        setHasFilters(!result);

        return () => {};
    }, [JSON.stringify(filterValues)]);

    function areAllPropertiesEmptyExceptStatusId(obj) {
        return Object.entries(obj)
            .filter(([key]) => key !== "status" && key !== "pageNumber" && key !== "pageSize") // Exclude the `status`, 'pageNumber' & "pageSize" property
            .every(([_, value]) => value === "" || value === 0); // Check if the remaining values are empty
    }

    const getLabelFromFilters = (f, field) => {
        let list, valueItem;

        // goes here if values are dynamic and are stored and retrieved from localstorage
        if (filterValues[field] && JSON.parse(localStorage.getItem(field))) {
            list = JSON.parse(localStorage.getItem(field));
            if (list) valueItem = list.find((item) => item.id.toString() === filterValues[field].toString());
            if (valueItem) {
                return valueItem.name;
            }
        }
        // goes here if values are coming from filtersOptions and dont need a separate call
        else if (f?.fieldType === "dropdown" && f?.values?.length > 0) {
            const result = f.values.find((v) => v.id?.toString() === filterValues[field]?.toString());

            if (!!result) {
                return result.name;
            }
        } else return filterValues[field];
    };

    return (
        <div>
            {tabs?.values?.length > 0 && (
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        onChange={handleTabChange}
                        sx={{ px: 1 }}
                        value={filterValues.status || tabs.values[0]}
                        variant="scrollable"
                    >
                        {tabs?.values?.map((tab, i) => (
                            <Tab
                                key={i}
                                iconPosition="end"
                                label={tab.name}
                                sx={{ minHeight: "auto" }}
                                tabIndex={0}
                                value={tab.id}
                            />
                        ))}
                    </Tabs>
                </Box>
            )}
            <Stack direction="row" spacing={1} sx={{ alignItems: "center", flexWrap: "wrap", px: 1, py: 2 }}>
                <Stack direction="row" spacing={1} sx={{ alignItems: "center", flex: "1 1 auto", flexWrap: "wrap" }}>
                    {filters
                        ?.filter((f) => f.fieldType !== "tab")
                        ?.map((f) => {
                            // console.log(f, f.field, filterValues.name);

                            return (
                                <FilterButton
                                    key={f.field}
                                    // displayValue={filterValues[f.field]}
                                    displayValue={getLabelFromFilters(f, f.field)}
                                    label={f.label}
                                    onFilterApply={(value) => {
                                        handleFilterChange(f.field, value);
                                    }}
                                    onFilterDelete={() => {
                                        handleFilterChange(f.field, "");
                                    }}
                                    popover={
                                        f.fieldType === "text" ? (
                                            <TextFilterPopover item={f} />
                                        ) : f.fieldType === "date" ? (
                                            <DateFilterPopover item={f} />
                                        ) : (
                                            <DropdownFilterPopover item={f} />
                                        )
                                    }
                                    value={filterValues[f.field] || ""}
                                />
                            );
                        })}

                    {hasFilters ? (
                        <Button 
                        onClick={handleClearFilters}
                        // onClick={onClearFilters}
                        >{t("base.button_clear", "Clear")}</Button>
                    ) : null}
                </Stack>

                {sorts.length > 0 && (
                    <FormControl sx={{ width: 150 }} size="small">
                        <Select name="sort" onChange={handleSortChange} value={sortDir}>
                            {sorts?.map((s) => (
                                <Option key={s.field} value={s.field}>
                                    {s.label}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Stack>
        </div>
    );
}

function TextFilterPopover({ item }) {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        setValue(initialValue ?? "");
    }, [initialValue]);

    return (
        <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={item.labelDescription}>
            <FormControl>
                <OutlinedInput
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            onApply(value);
                        }
                    }}
                    value={value}
                />
            </FormControl>
            <Stack direction={"row"} spacing={1}>
                <Button
                    onClick={() => {
                        onApply(value);
                    }}
                    variant="contained"
                    sx={{ flexGrow: 1 }}
                >
                    Apply
                </Button>
                {initialValue !== "" && (
                    <Button
                        onClick={() => {
                            onApply("");
                        }}
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                    >
                        Clear
                    </Button>
                )}
            </Stack>
        </FilterPopover>
    );
}

function DropdownFilterPopover({ item }) {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = useState("");
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (item.valuesSource === "dynamic") {
            fetchDropdownValues();
        } else {
            setValues(item.values);
        }

        return () => {};
    }, []);

    useEffect(() => {
        setValue(initialValue ?? "");
    }, [initialValue]);

    const fetchDropdownValues = async () => {
        const result = await fetchData(settings.api().host + item.valuesUrl, "GET");
        if (result.success) {
            setValues(result.data);
            // store the value object so we dont need to make another call just to get the name value of the selected id
            localStorage.setItem(item.field, JSON.stringify(result.data));
        }
    };

    return (
        <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={item.labelDescription}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={item.label}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            onApply(value);
                        }
                    }}
                >
                    {values?.map((v, i) => (
                        <MenuItem key={i} value={v.id}>
                            {v.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack direction={"row"} spacing={1}>
                <Button
                    onClick={() => {
                        onApply(value);
                    }}
                    variant="contained"
                    sx={{ flexGrow: 1 }}
                >
                    Apply
                </Button>
                {initialValue !== "" && (
                    <Button
                        onClick={() => {
                            onApply("");
                        }}
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                    >
                        Clear
                    </Button>
                )}
            </Stack>
        </FilterPopover>
    );
}

function DateFilterPopover({ item }) {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initialValue ?? "");
    }, [initialValue]);

    const handleDates = (date) => {
        setValue(date.format("YYYY-MM-DD"));
    };

    return (
        <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={item.labelDescription}>
            <FormControl>
                <DatePicker
                    format="D MMM, YYYY"
                    label={item.label}
                    value={dayjs(value)}
                    onChange={(d) => handleDates(d)}
                    slotProps={{
                        textField: {
                            width: 250,
                            size: "small",
                        },
                    }}
                />
            </FormControl>
            <Stack direction={"row"} spacing={1}>
                <Button
                    onClick={() => {
                        onApply(value);
                    }}
                    variant="contained"
                    sx={{ flexGrow: 1 }}
                >
                    Apply
                </Button>
                {initialValue !== "" && (
                    <Button
                        onClick={() => {
                            onApply("");
                        }}
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                    >
                        Clear
                    </Button>
                )}
            </Stack>
        </FilterPopover>
    );
}
