import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BootstrapInput from "../../components/BootstrapTextField";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { Password as PasswordIcon } from "@phosphor-icons/react/dist/ssr/Password";
// import StoreIcon from "@mui/icons-material/Store";
import { UserGear as UserGearIcon } from "@phosphor-icons/react/dist/ssr/UserGear";
import dayjs from "dayjs";
import fetchData, { fetchPublicData } from "../../Helpers/apiCalls";
import BootstrapSelect from "../../components/BootstrapSelect";
import AccountLayout from "./Account";
import { loadTranslations } from "../../locales/i18n";
import WarningIcon from "@mui/icons-material/Warning";

const Profile = () => {
    const domain = getDomain();
    const subdomain = getSubdomain();

    const [timezones, setTimezones] = useState([]);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        phone: "",
        position: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timeZoneId: 0,
    });
    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        userId: 0,
    });
    const [localeChanged, setLocaleChanged] = useState(false);
    // const [locales, setLocales] = useState([]);
    const [showError, setShowError] = useState(false);
    const [validatePassword, setValidatePassword] = useState("");

    // NOTE: First level elements are groups.
    const navItems = [
        {
            key: "personal",
            title: "Personal",
            items: [
                { key: "account", title: "Account", href: "/portal/account", icon: "user-circle" },
                // { key: "notifications", title: "Notifications", href: "/portal/notifications", icon: "bell" },
                { key: "security", title: "Security", href: "", icon: "lock-key" },
            ],
        },
        // {
        //     key: "organization",
        //     title: "Organization",
        //     items: [
        //         { key: "billing", title: "Billing & plans", href: "", icon: "credit-card" },
        //         { key: "team", title: "Team", href: "", icon: "users-three" },
        //         {
        //             key: "integrations",
        //             title: "Integrations",
        //             href: "",
        //             icon: "plugs-connected",
        //         },
        //     ],
        // },
    ];

    useEffect(() => {
        fetchUser();
        return () => {};
    }, []);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        // check if locale is being changed && if changed value is different than selected
        if (name === "localeId" && value !== userInfo.locale.selectedId) {
            setLocaleChanged(true);
        }
        setUserInfo({ ...userInfo, [name]: value });
    };

    const updateUser = async () => {
        const body = {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            phone: userInfo.phone,
            localeId: userInfo.localeId,
            timeZoneId: userInfo.timeZoneId,
        };
        const result = await fetchData(settings.api().account, "PUT", body);
        if (result.success) {
            const languageCode = JSON.parse(localStorage.getItem("userOrganizationInfo"))?.locales?.find(
                (l) => l.id === body.localeId
            ).code;

            console.log(languageCode);

            localeChanged && languageCode && loadTranslations(languageCode);
            // update user info on page after PUT
            fetchUser();
        }
        // update portal language when locale is changed.
        // get language code to update portal language.
    };

    const fetchUser = async () => {
        const result = await fetchData(settings.api().account, "GET");
        if (result.success) {
            setUserInfo({
                ...result.data,
                localeId: result?.data?.locale?.selectedId,
                timeZoneId: result?.data?.timeZone?.selectedId,
            });

            if (!result?.data?.timeZone?.useStaticValues) {
                fetchTimeZones(result?.data?.timeZone?.link?.href, result?.data?.timeZone?.link?.method);
            }
        }
    };

    const fetchTimeZones = async (path, method) => {
        const result = await fetchData(settings.api().route + path, method);
        if (result.success) {
            setTimezones(result.data);
        }
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setUpdatePassword({
            ...updatePassword,
            [name]: value,
        });

        if (name === "newPassword") {
            validatePasswordWithApi(value);
        }
    };

    const handleUpdatePasswordChange = () => {
        if (
            updatePassword.currentPassword === "" ||
            updatePassword.newPassword === "" ||
            updatePassword.confirmPassword === ""
        ) {
            setShowError(true);
            return;
        }
        if (updatePassword.newPassword !== updatePassword.confirmPassword) {
            setShowError(true);
            return;
        }
        fetch(settings.api().users + `/${userInfo.id}/change-password`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            method: "PUT",
            body: JSON.stringify(),
        }).then((res) => {
            console.log(res);
            return res.json();
        });
    };

    const validatePasswordWithApi = async (value) => {
        const result = await fetchPublicData(settings.api().validatePassword, "POST", value);
        if (result.success) {
            setValidatePassword("");
        } else {
            setValidatePassword(result.message);
        }
    };

    console.log(userInfo);

    return (
        <AccountLayout
            navItems={navItems}
            profile={{ name: `${userInfo.firstName} ${userInfo.lastName}`, secondary: userInfo.login }}
        >
            <div>
                <div>
                    <Typography variant="h4">Account</Typography>
                </div>
                <Card sx={{ mt: 4 }}>
                    <CardHeader
                        // avatar={<Avatar>Logo</Avatar>}
                        title={
                            <>
                                <Stack direction="row" spacing={2} sx={{ alignItems: "center", flex: "1 1 auto" }}>
                                    <Avatar src="/assets/avatar-1.png" sx={{ width: 64, height: 64 }}>
                                        Logo
                                    </Avatar>
                                    <div>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ alignItems: "center", flexWrap: "wrap" }}
                                        >
                                            <Typography variant="h4">{userInfo?.siteName}</Typography>
                                        </Stack>
                                        <Typography color="textSecondary" variant="body1">
                                            {userInfo?.siteCode} site_code
                                        </Typography>
                                    </div>
                                </Stack>
                            </>
                        }
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}
                            sx={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Role
                                </Typography>
                                <Chip
                                    icon={<UserGearIcon color="#15b79f" weight="fill" />}
                                    label="Manager"
                                    size="small"
                                    variant="outlined"
                                    // color="#15b79f"
                                    // sx={{ backgroundColor: "#15b79f", color: "#FFF" }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Employee Code
                                </Typography>
                                <Typography variant="subtitle1">{userInfo?.code}12838DQ83DA</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Department
                                </Typography>
                                <Typography variant="subtitle1">{userInfo?.department}deparment</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Position
                                </Typography>
                                <Typography variant="subtitle1">{userInfo?.position}position</Typography>
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Account Created
                                </Typography>
                                <Typography variant="subtitle1">
                                    {dayjs(userInfo?.createUtc).format("MMM D, YYYY")}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 4 }}>
                    <CardHeader
                        avatar={<Avatar>{/* <StorefrontIcon fontSize="large" /> */}</Avatar>}
                        title={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                    Basic Details
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    display={"inline"}
                                    sx={{ marginRight: "5px" }}
                                >
                                    Last modified: {dayjs(userInfo.modifiedUtc).format("MMM DD, YYYY")}
                                </Typography>
                            </Box>
                        }
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Typography variant="subtitle1">My Information</Typography>
                        <Divider />
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                display: "grid",
                                gridTemplateColumns: { sm: "1fr 1fr" },
                                gap: 2,
                                py: 2,
                            }}
                        >
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>First Name</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"firstName"}
                                    value={userInfo.firstName}
                                    onChange={handleUserInfoChange}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Last Name</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"lastName"}
                                    value={userInfo.lastName}
                                    onChange={handleUserInfoChange}
                                />
                            </FormControl>
                            {/* <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Email</strong>
                                </InputLabel>
                                <BootstrapInput
                                    name={"email"}
                                    type="email"
                                    onChange={(e) => {
                                        // Validate email as the user types
                                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        const isValid = emailRegex.test(e.target.value);
                                        isValid && handleUserInfoChange(e);
                                    }}
                                    value={userInfo.email}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Login
                                </InputLabel>
                                <BootstrapInput name={"login"} value={userInfo.login} onChange={handleUserInfoChange} />
                            </FormControl> */}
                            <FormControl variant="standard" fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Phone Number
                                </InputLabel>
                                <BootstrapInput
                                    name={"phone"}
                                    type="tel"
                                    maxLength={15}
                                    value={userInfo.phone}
                                    // onChange={handleUserInfoChange}
                                    onChange={(e) => {
                                        let input = e.target.value;
                                        input = input.replace(/\D/g, ""); // Remove non-digit characters
                                        if (input.length > 15) {
                                            input = input.substring(0, 15); // Truncate to 15 digits
                                        }
                                        handleUserInfoChange({ target: { name: "phone", value: input } });
                                    }}
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Profile Public"
                                checked={userInfo?.profilePublic}
                                onChange={() =>
                                    setUserInfo({
                                        ...userInfo,
                                        profilePublic: !userInfo.profilePublic,
                                    })
                                }
                            />
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    <strong>Language</strong>
                                </InputLabel>
                                <BootstrapSelect
                                    value={userInfo?.localeId}
                                    name="localeId"
                                    onChange={handleUserInfoChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Language--</em>
                                    </MenuItem>
                                    {userInfo?.locale?.useStaticValues &&
                                        userInfo?.locale?.values?.map((l) => (
                                            <MenuItem key={l.id} value={l.id}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                </BootstrapSelect>
                            </FormControl>
                            <FormControl fullWidth required={true}>
                                <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                    <strong>Time Zone</strong>
                                </InputLabel>
                                <BootstrapSelect
                                    value={userInfo.timeZoneId}
                                    name={"timeZoneId"}
                                    onChange={handleUserInfoChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem disabled value={0}>
                                        <em>--Select Time Zone--</em>
                                    </MenuItem>
                                    {timezones.map((t) => (
                                        <MenuItem key={t.id} value={t.id}>
                                            {t.name}
                                        </MenuItem>
                                    ))}
                                </BootstrapSelect>
                            </FormControl>
                        </Box>
                        <div style={{ marginTop: "0px" }}>
                            <Button variant="outlined" style={{ marginRight: "10px" }} onClick={updateUser}>
                                Update
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 4 }}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <PasswordIcon fontSize="large" />
                            </Avatar>
                        }
                        title={<Typography variant="h6">Account Password</Typography>}
                    />
                    <CardContent sx={{ pb: 0 }}>
                        <Typography variant="subtitle1">Change Password</Typography>
                        <Divider />
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                display: "grid",
                                gridTemplateColumns: { sm: "1fr 1fr" },
                                gap: 2,
                                py: 2,
                            }}
                        >
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Current Password</strong>
                                </InputLabel>
                                <BootstrapInput
                                    type="password"
                                    name={"currentPassword"}
                                    onChange={handlePasswordChange}
                                    value={updatePassword.currentPassword}
                                />
                            </FormControl>
                            <div></div>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>New Password</strong>
                                </InputLabel>
                                <BootstrapInput
                                    type="password"
                                    name={"newPassword"}
                                    onChange={handlePasswordChange}
                                    value={updatePassword.newPassword}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth required={true}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    <strong>Confirm Password</strong>
                                </InputLabel>
                                <BootstrapInput
                                    type="password"
                                    name={"confirmPassword"}
                                    onChange={handlePasswordChange}
                                    value={updatePassword.confirmPassword}
                                />
                            </FormControl>
                        </Box>
                        {!!validatePassword && (
                            <Stack alignItems="center" direction="row" gap={1}>
                                <WarningIcon color="error" />
                                <Typography variant="body1" color="error">
                                    {validatePassword}
                                </Typography>
                            </Stack>
                        )}
                        <div style={{ marginTop: "0px" }}>
                            <Button
                                variant="outlined"
                                color="error"
                                style={{ marginRight: "10px" }}
                                onClick={handleUpdatePasswordChange}
                            >
                                Update Password
                            </Button>
                        </div>
                    </CardContent>
                </Card>

                <Dialog
                    open={showError}
                    onClose={() => setShowError(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>Oops!</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please ensure all password fields are filled, and New and Confirm passwords are the same.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setShowError(false)}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </AccountLayout>
    );
};

export default Profile;
