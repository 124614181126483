import React from "react";
import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
import { ListBullets as ListBulletsIcon } from "@phosphor-icons/react/dist/ssr/ListBullets";

const columns = [
    { field: "partNo", name: "Part #", width: "60px" },
    { field: "brand", name: "Brand", width: "60px" },
    { field: "productName", name: "Product Name", width: "100px" },
    { field: "size", name: "Size", width: "60px" },
    { field: "loadSpeed", name: "Load Speed", width: "70px" },
    { field: "type", name: "Type", width: "40px" },
    { formatter: (row) => <Typography>${row.bookedPrice.toFixed(2)}</Typography>, name: "Booked Price", width: "50px" },
    { field: "previousBooked", name: "Prev. Booked", width: "80px" },
    { field: "previousPurchased", name: "Prev. Purchased", width: "80px" },
    { field: "totalQty", name: "Total Qty.", width: "70px" },
    { field: "distributor", name: "Distributor", width: "100px" },
];

const rows = [
    {
        partNo: "137862",
        brand: "Michelin",
        productName: "X-Ice",
        size: "225/60R17",
        loadSpeed: "99H",
        type: "W",
        bookedPrice: 125.8,
        previousBooked: 80,
        previousPurchased: 96,
        totalQty: 70,
        distributor: "Oshawa",
    },
    {
        partNo: "37846",
        brand: "Michelin",
        productName: "Latitude X-Ice",
        size: "235/65R17",
        loadSpeed: "108T",
        type: "W",
        bookedPrice: 140.33,
        previousBooked: 80,
        previousPurchased: 100,
        totalQty: 85,
        distributor: "Mississauga",
    },
];

export default function OrderReview() {
    return (
        <Card>
            <CardHeader
                title={<Typography variant="h6">Review Tire Order</Typography>}
                avatar={<Avatar><ListBulletsIcon size={24} /></Avatar>}
            />
            <CardContent>
                <DataTable dense={true} columns={columns} rows={rows} />
                <DataTablePagination count={2} pageNumber={1} pageSize={25} />
            </CardContent>
        </Card>
    );
}
