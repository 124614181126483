import React from "react";
import { Box, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import settings from "../settings/api";
import { useState } from "react";
import fetchData from "../Helpers/apiCalls";

export default function Versions() {
    const [versions, setVersions] = useState([]);

    useEffect(() => {
        fetchVersions();

        return () => {};
    }, []);

    const fetchVersions = async () => {
        const result = await fetchData(settings.api().versions, "GET");
        if (result.success) {
            setVersions(result.data)
        }
    }

    return (
        <div>
            <h1>Versions</h1>
            <Card>
                <CardContent>
                    <Stack>
                        {versions?.map((v, k) => (
                            <Box>
                                <Typography>Version: {v.version}</Typography>
                                <Typography color="textSecondary">Released: {v.release_date}</Typography>
                                <ul>
                                    {v.changes.map((c) => (
                                        <li>{c}</li>
                                    ))}
                                </ul>
                                <Divider sx={{ mb: 2 }} />
                            </Box>
                        ))}
                    </Stack>
                </CardContent>
            </Card>
        </div>
    );
}
