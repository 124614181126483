import React, { Suspense, lazy } from "react";
import Login from "../pages/Login";
import Home from "../pages/Home";
import PortalLayout from "../layout/Portal/Portal";
import Error404 from "../pages/404";
import Versions from "../pages/Versions";
import TmpPage from "../pages/TmpPage";
import ProtectedRouteWrapper from "../Helpers/PrivateRoute";
import AccountActivation from "../pages/UserAuthPages/AccountActivation";
import UserAuthNotification from "../pages/UserAuthPages/Notification";
import UserAuthEmailStatus from "../pages/UserAuthPages/EmailStatus";
import UserAuthEmailVerification from "../pages/UserAuthPages/EmailVerification";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import TermsAndConditionsPage from "../pages/TermsAndConditions";

// Standard
import Groups from "../pages/PortalAdmin/Groups/Groups";
// import NewGroup from "../pages/PortalAdmin/Groups/NewGroup";
// import NewEditUser from "../components/Users/NewEditUser";
import AddUser from "../components/Users/AddUser";
import Cases from "../pages/StandardPages/Cases";
import Lessons from "../pages/StandardPages/Lessons";
import Assessments from "../pages/StandardPages/Assessments";
import MySite from "../pages/StandardPages/MySite/MySite";
import LoadingSpinner from "../components/Spinner";
import Profile from "../pages/StandardPages/Profile";
import TireQuotePage from "../pages/StandardPages/TireQuotePage";
import ProductDetailPage from "../pages/StandardPages/TireDetails";
import { EditSiteForm } from "../components/Users/EditSiteForm";
import UserDetails from "../components/Users/UserDetails";
import NewAssessments from "../pages/StandardPages/NewAssessments";
import Notifications from "../pages/StandardPages/Notifications";
import Pricing from "../pages/SitesAdmin/TireQuote/Pricing";
import Featured from "../pages/SitesAdmin/TireQuote/Featured";
import Promotions from "../pages/SitesAdmin/TireQuote/Promotions";
import Stock from "../pages/SitesAdmin/TireQuote/Stock";
import TPO from "../pages/SitesAdmin/TPO/TPO";
import Settings from "../pages/SitesAdmin/TPO/Settings";
import Orders from "../pages/StandardPages/TireQuotePage/Orders";
import Vehicles from "../pages/StandardPages/TireQuotePage/Vehicles";
import Tires from "../pages/StandardPages/TireQuotePage/Tires";
import Wheels from "../pages/StandardPages/TireQuotePage/Wheels";
import Packages from "../pages/StandardPages/TireQuotePage/Packages";
import Accessories from "../pages/StandardPages/TireQuotePage/Accessories";
import Reports from "../pages/StandardPages/TireQuotePage/Reports";
import Order from "../pages/StandardPages/TireQuotePage/Order";
import UserSecurity from "../pages/StandardPages/Site/UserSecurity";
import UserProfile from "../pages/StandardPages/Site/UserProfile";

// Sites Admin
import SitesAdminDashboard from "../pages/SitesAdmin/Dashboard";
import TireQuoteTires from "../pages/SitesAdmin/TireQuote/Data/Tires";
import TireQuoteWheels from "../pages/SitesAdmin/TireQuote/Data/Wheels";
import TireWheelPackages from "../pages/SitesAdmin/TireQuote/Data/Packages";
import TireQuoteAccessories from "../pages/SitesAdmin/TireQuote/Data/Accessories";
import PricingTemplates from "../pages/SitesAdmin/TireQuote/Pricing/Templates";

// Portal Admin
import OrgAdminDashboard from "../pages/PortalAdmin/Dashboard";
import OrgAdminSites from "../pages/PortalAdmin/Sites/Sites";
import GroupTypes from "../pages/PortalAdmin/GroupTypes/GroupTypes";
import OrgAdminTrainingSettings from "../pages/PortalAdmin/Training/Settings";
import OrgAdminAssessmentSettings from "../pages/PortalAdmin/Assessment/Settings";
import OrgAdminTACSettings from "../pages/PortalAdmin/TAC/Settings";
import OrgAdminTrainingCourses from "../pages/PortalAdmin/Training/Courses/Courses";
import OrgAdminTrainingCourseCategories from "../pages/PortalAdmin/Training/Courses/CourseCategories";
import OrgAdminTrainingLessons from "../pages/PortalAdmin/Training/Courses/Lessons";
import PortalUsersList from "../pages/PortalAdmin/Users/List";
import PortalTireQuoteSettings from "../pages/PortalAdmin/TireQuote/Settings";

// System Admin
import MultiOrgAdminDashboard from "../pages/SystemAdmin/Dashboard";
import MultiOrgList from "../pages/SystemAdmin/Organizations/Organizations";
import TPOSchedules from "../pages/SystemAdmin/TPO/TPOSchedules";
import TPOBookings from "../pages/SystemAdmin/TPO/TPOBookings";
import AddEditTPOSchedule from "../pages/SystemAdmin/TPO/AddEditTPOSchedule";
import TPOSchedule from "../pages/SystemAdmin/TPO/Schedule/TPOSchdule";
import SystemAdminOrganization from "../pages/SystemAdmin/Organizations/Organization";
import SitesTireQuoteSettings from "../pages/SitesAdmin/TireQuote/Setting";
import PortalTPOSettings from "../pages/PortalAdmin/TPO/Settings";
import SystemTPOSettings from "../pages/SystemAdmin/TireQuote/Settings";
import SystemTireQuoteSettings from "../pages/SystemAdmin/TireQuote/Settings";
import BookingWizard from "../pages/SitesAdmin/TPO/BookingWizard/BookingWizard";

const DashboardPage = lazy(() => import("../pages/StandardPages/Dashboard/Dashboard"));

const protectedRoute = (Component) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRouteWrapper Component={Component} />
        </Suspense>
    );
};

export const routes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "activation",
        element: <AccountActivation />,
    },
    {
        path: "notification",
        element: <UserAuthNotification />,
    },
    {
        path: "email-status",
        element: <UserAuthEmailStatus />,
    },
    {
        path: "verify-email",
        element: <UserAuthEmailVerification />,
    },
    {
        path: "terms-and-conditions",
        element: <TermsAndConditionsPage />,
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "portal",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },

            {
                path: "tire-quote",
                children: [
                    {
                        index: true,
                        element: <TireQuotePage />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "orders/:id",
                        element: <Order />,
                    },
                    {
                        path: "vehicles",
                        element: <Vehicles />,
                    },
                    {
                        path: "tires",
                        element: <Tires />,
                    },
                    {
                        path: "tires/:id",
                        element: <ProductDetailPage />,
                    },
                    {
                        path: "wheels",
                        element: <Wheels />,
                    },
                    {
                        path: "packages",
                        element: <Packages />,
                    },
                    {
                        path: "accessories",
                        element: <Accessories />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                ],
            },
            {
                path: "lessons",
                element: <Lessons />,
            },
            {
                path: "assessments",
                children: [
                    {
                        index: true,
                        element: <Assessments />,
                    },
                    {
                        path: "new",
                        element: <NewAssessments />,
                    },
                ],
            },
            {
                path: "cases",
                element: <Cases />,
            },
            {
                path: "site",
                children: [
                    {
                        index: true,
                        element: <MySite />,
                    },
                    {
                        path: "edit",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "users/:id",
                        children: [
                            {
                                index: true,
                                element: <UserProfile />,
                            },
                            {
                                path: "security",
                                element: <UserSecurity />,
                            },
                        ],
                    },
                    {
                        path: "add-user",
                        // element: <NewEditUser mode="create" />
                        element: <AddUser />,
                    },
                ],
            },
            {
                path: "account",
                element: <Profile />,
            },
            {
                path: "notifications",
                element: <Notifications />,
            },
            {
                path: "user",
                element: <UserDetails />,
            },
            {
                path: "versions",
                element: <Versions />,
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ],
    },
    {
        path: "admin/sites/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <SitesAdminDashboard />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "data/tires",
                        element: <TireQuoteTires />,
                    },
                    {
                        path: "data/wheels",
                        element: <TireQuoteWheels />,
                    },
                    {
                        path: "data/packages",
                        element: <TireWheelPackages />,
                    },
                    {
                        path: "data/accessories",
                        element: <TireQuoteAccessories />,
                    },
                    {
                        path: "pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "pricing/templates",
                        element: <PricingTemplates />,
                    },
                    {
                        path: "featured",
                        element: <Featured />,
                    },
                    {
                        path: "promotions",
                        element: <Promotions />,
                    },
                    {
                        path: "stock",
                        element: <Stock />,
                    },
                    // {
                    //     path: "tpo",
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <SitesTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        index: true,
                        element: <TPO />,
                    },
                    {
                        path: "booking",
                        element: <BookingWizard />,
                    },
                    {
                        path: "booking/:bookingId",
                        element: <BookingWizard />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/organizations/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <OrgAdminDashboard />,
            },
            {
                path: "sites",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <OrgAdminSites />,
                    },
                    {
                        path: ":id",
                        element: <TmpPage />,
                    },
                    {
                        path: "groups",
                        element: <Groups />,
                    },
                    {
                        path: "group-types",
                        element: <GroupTypes />,
                    },
                ],
            },
            {
                path: "users",
                element: <PortalUsersList />,
            },
            {
                path: "users/:id",
                element: <TmpPage />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "settings",
                        element: <PortalTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <PortalTPOSettings />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <OrgAdminTrainingCourses />,
                    },
                    {
                        path: "lessons",
                        element: <OrgAdminTrainingLessons />,
                    },
                    {
                        path: "course-categories",
                        element: <OrgAdminTrainingCourseCategories />,
                    },
                    {
                        path: "settings",
                        element: <OrgAdminTrainingSettings />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminAssessmentSettings />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminTACSettings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/global",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <MultiOrgAdminDashboard />,
            },
            {
                path: "organizations",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <MultiOrgList />,
                    },
                    {
                        path: ":id",
                        element: <SystemAdminOrganization />,
                    },
                ],
            },
            {
                path: "tire-quote",
                children: [
                    // {
                    //     path: "tpo",
                    //     children: [
                    //         {
                    //             path: "schedules",
                    //             element: <TPOSchedules />,
                    //         },
                    //         {
                    //             path: "schedules/:id",
                    //             element: <TPOSchedule />,
                    //         },
                    //         {
                    //             path: "schedules/add",
                    //             element: <AddEditTPOSchedule mode={"create"} />,
                    //         },
                    //         {
                    //             path: "bookings",
                    //             element: <TPOBookings />,
                    //         },
                    //     ],
                    // },
                    {
                        path: "settings",
                        element: <SystemTireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "schedules",
                        element: <TPOSchedules />,
                    },
                    {
                        path: "schedules/add",
                        element: <AddEditTPOSchedule mode={"create"} />,
                    },
                    {
                        path: "schedules/:id",
                        element: <TPOSchedule />,
                    },
                    {
                        path: "bookings",
                        element: <TPOBookings />,
                    },
                    {
                        path: "settings",
                        element: <SystemTPOSettings />,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Error404 />,
    },
];
