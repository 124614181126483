import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import settings from "../../../settings/api";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";
import { DownloadSimple as DownloadSimpleIcon } from "@phosphor-icons/react/dist/ssr/DownloadSimple";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link as RouterLink } from "react-router-dom";

const columns = [
    {
        formatter: (row) => (
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <div>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        // to={`/admin/organizations/${orgId}/users/1`}
                        sx={{ whiteSpace: "nowrap" }}
                        variant="subtitle2"
                    >
                        {row.packageName}
                    </Link>
                    <Typography color="text.secondary" variant="body2">
                        {row.packageId}
                    </Typography>
                </div>
            </Stack>
        ),
        name: "Package",
        width: "150px",
    },
    { field: "make", name: "Make", width: "80px" },
    { field: "model", name: "Model", width: "100px" },
    { field: "year", name: "Year", width: "80px" },
    { field: "trim", name: "Trim", width: "70px" },
    { field: "dtcTrim", name: "DTC Trim", width: "60px" },
    { field: "tpms", name: "TPMS", width: "100px" },
    { field: "runFlat", name: "Run Flat", width: "80px" },
    { field: "tireCategoryPrimary", name: "Tire Cat. (Primary)", width: "90px" },
    { field: "tireCategorySecondary", name: "Tire Cat. (Secondary)", width: "90px" },
    { field: "tireSize", name: "Tire Size", width: "100px" },
    { field: "availableRims", name: "Available Rims", width: "80px" },
    { field: "lastUpdate", name: "Last Update", width: "140px" },
    {
        formatter: (row) => {
            return <IconButton>{row.favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}</IconButton>;
        },
        name: "Favourite",
        width: "80px",
        align: "center",
    },
];

const rows = [
    {
        packageName: "Winter Package A",
        packageId: 2831786,
        year: "2004-2021",
        make: "Nissan",
        model: "Versa",
        trim: "All Models",
        dtcTrim: "",
        tpms: "Yes (Direct)",
        runFlat: "No",
        tireCategoryPrimary: "Winter Passanger",
        tireCategorySecondary: "Winter SUV/Truck",
        tireSize: "265/70/17",
        availableRims: 5,
        dnp: 73.03,
        msrp: 121.73,
        hasVisualizer: "Yes",
        disc: "No",
        lastUpdate: "January 30th, 2020",
    },
    {
        packageName: "All-Seaons Package B",
        packageId: 2831786,
        year: "2004-2021",
        make: "Nissan",
        model: "Versa",
        trim: "All Models",
        dtcTrim: "",
        tpms: "Yes (Direct)",
        runFlat: "No",
        tireCategoryPrimary: "Winter Passanger",
        tireCategorySecondary: "Winter SUV/Truck",
        tireSize: "265/70/17",
        availableRims: 5,
        dnp: 73.03,
        msrp: 121.73,
        hasVisualizer: "Yes",
        disc: "No",
        lastUpdate: "January 30th, 2020",
    },
];

const sortingOptions = [];
const filteringOptions = [
    {
        field: "status",
        label: "Status",
        labelDescription: "Filer by Status",
        fieldType: "tab",
        values: ["All", "Favourite"],
    },
];
export default function Packages() {
    const domain = getDomain();
    const subdomain = getSubdomain();
    const [selectedValues, setSelectedValues] = useState({
        year: "",
        make: "",
        model: "",
        trim: "",
        option: "",
    });
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchData = (url, method, setState) => {
        fetch(url, {
            method,
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .then((data) => {
                // console.log(data);
                setState(data);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        fetchData(settings.api().vehicleYear, "GET", setYears);
    }, []);

    const handleSelect = (field, value, linkRel, setState) => {
        const resetFields = {
            year: { make: "", model: "", trim: "", option: "" },
            make: { model: "", trim: "", option: "" },
            model: { trim: "", option: "" },
            trim: { option: "" },
        };

        setSelectedValues((prevValues) => ({
            ...prevValues,
            [field]: value,
            ...resetFields[field],
        }));

        const link = value?.links.find((link) => link.rel === linkRel);
        if (link) {
            fetchData(settings.api().host + link.href, link.method, setState);
        }
    };

    const handleSelectYear = (e) => handleSelect("year", e.target.value, "vehicleMakes", setMakes);
    const handleSelectMake = (e) => handleSelect("make", e.target.value, "vehicleModels", setModels);
    const handleSelectModel = (e) => handleSelect("model", e.target.value, "vehicleTrims", setTrims);
    const handleSelectTrim = (e) => handleSelect("trim", e.target.value, "vehicleOptions", setOptions);

    return (
        <div>
            <h1>Packages</h1>
            <Card
            // sx={{maxWidth: {md: "1200px"}}}
            >
                <CardContent>
                    <Typography gutterBottom sx={{ fontSize: "18px" }}>
                        Search Vehicle
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                        <FormControl sx={{ flexGrow: 1, minWidth: "150px" }} size="small">
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValues.year}
                                label="Year"
                                onChange={(e) => handleSelectYear(e)}
                                MenuProps={{
                                    sx: { maxHeight: "400px" },
                                }}
                            >
                                {years.length > 0 &&
                                    years.map((y) => (
                                        <MenuItem key={y.year} value={y}>
                                            {y.year}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ flexGrow: 1, minWidth: "150px" }}
                            disabled={selectedValues.year === null}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-label">Make</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValues.make}
                                label="Make"
                                onChange={(e) => handleSelectMake(e)}
                                MenuProps={{
                                    sx: { maxHeight: "400px" },
                                }}
                            >
                                {makes.length > 0 &&
                                    makes.map((m) => (
                                        <MenuItem key={m.makeId} value={m}>
                                            {m.makeName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ flexGrow: 1, minWidth: "150px" }}
                            disabled={selectedValues.make === null}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-label">Model</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValues.model}
                                label="Model"
                                onChange={(e) => handleSelectModel(e)}
                                MenuProps={{
                                    sx: { maxHeight: "400px" },
                                }}
                            >
                                {models.length > 0 &&
                                    models.map((m) => (
                                        <MenuItem key={m.modelId} value={m}>
                                            {m.modelName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ flexGrow: 1, minWidth: "150px" }}
                            disabled={selectedValues.model === null}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-label">Trim</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValues.trim}
                                label="Trim"
                                onChange={(e) => handleSelectTrim(e)}
                                MenuProps={{
                                    sx: { maxHeight: "400px" },
                                }}
                            >
                                {trims.length > 0 &&
                                    trims.map((t) => (
                                        <MenuItem key={t.trimId} value={t}>
                                            {t.trimName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ flexGrow: 1, minWidth: "150px" }}
                            disabled={selectedValues.trim === null}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-label">Option</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValues.option}
                                label="Option"
                                // onChange={handleChange}
                                MenuProps={{
                                    sx: { maxHeight: "400px" },
                                }}
                            >
                                {options.length > 0 &&
                                    options.map((t) => (
                                        <MenuItem key={t.trimId} value={t}>
                                            {t.trimName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ minWidth: "200px" }}>
                        <div style={{ margin: "10px 0 5px" }}>
                            <Typography variant="body2" color="textSecondary">
                                Recent searches
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap", // Allows text wrapping
                                    alignItems: "center",
                                }}
                            >
                                <Link>
                                    <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        sx={{ textDecoration: "underline", mr: 2 }}
                                    >
                                        Nissan Rogue
                                    </Typography>
                                </Link>
                            </Box>
                        </div>
                        <Box>
                            <Button variant="outlined" sx={{ mr: 2 }}>
                                Clear
                            </Button>
                            <Button variant="contained">Search</Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ alignItems: "flex-start" }}>
                    <Box sx={{ flex: "1 1 auto" }}>
                        <CardHeader title={<Typography variant="h6">Package Results</Typography>} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                        <Button startIcon={<DownloadSimpleIcon />} variant="contained" color="primary">
                            CSV
                        </Button>
                    </Box>
                </Stack>

                <DataTableFilters
                    sorts={sortingOptions}
                    filters={filteringOptions}
                    // initialSortDir={sortingOptions[0]?.field}
                />
                {/* <Box sx={{ overflowX: "auto", maxWidth: }}>
                    <DataTable columns={columns} rows={rows} dense={true} />
                </Box> */}
                <DataTable columns={columns} rows={rows} dense={true} />
                <DataTablePagination count={1} pageSize={25} pageNumber={1} />
            </Card>
        </div>
    );
}
