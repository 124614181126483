import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDataTable = (fetchDataCallback, initialPagination = { pageNumber: 1, pageSize: 25 }) => {
    const location = useLocation();

    // State management
    const [filters, setFilters] = useState({});
    const [sorts, setSorts] = useState("");
    const [pagination, setPagination] = useState(initialPagination);
    const [data, setData] = useState(null);

    // Fetch data when URL search string changes
    useEffect(() => {
        const query = location.search || generateQueryString(filters, sorts, pagination);
        fetchScheduleData(query);
    }, [location.search]);

    // Fetch data function
    const fetchScheduleData = async (query) => {
        const result = await fetchDataCallback(query);
        if (result.success) {
            setData(result.data);
        }
    };

    // Update search params and URL
    const generateQueryString = (filters, sorts, pagination) => {
        const params = new URLSearchParams();

        // Add filters
        Object.entries(filters).forEach(([key, value]) => {
            if (value) params.set(key, value);
        });

        // Add sortDir if provided
        if (sorts) {
            params.set("sortDir", sorts);
        }

        // Add pagination
        params.set("pageNumber", pagination.pageNumber);
        params.set("pageSize", pagination.pageSize);

        const queryString = `?${params.toString()}`;
        window.history.replaceState(null, "", queryString);

        return queryString;
    };

    // Handlers for updating state
    const handlePaginationChange = ({ pageNumber, pageSize }) => {
        setPagination({ pageNumber, pageSize });
        const query = generateQueryString(filters, sorts, { pageNumber, pageSize });
        fetchScheduleData(query);
    };

    const handleFilterUpdate = (newFilters, newSortDir) => {
        setFilters(newFilters);
        setSorts(newSortDir);
        setPagination({ pageNumber: 1, pageSize: initialPagination.pageSize });
        const query = generateQueryString(newFilters, newSortDir, {
            pageNumber: 1,
            pageSize: initialPagination.pageSize,
        });
        fetchScheduleData(query);
    };

    return {
        data,
        filters,
        sorts,
        pagination,
        handlePaginationChange,
        handleFilterUpdate,
    };
};

export default useDataTable;


// import { useState, useEffect } from 'react';

// export const useDataTable = ({
//     fetchData, // function passed from parent
//     filters,
//     sorts,
//     pagination,
//     setPagination,
//     setFilters,
//     setSorts,
// }) => {
//     // Set up internal state for pagination and filters
//     const [paginationState, setPaginationState] = useState(pagination);

//     const handlePaginationChange = ({ pageNumber, pageSize }) => {
//         setPaginationState({ pageNumber, pageSize });
//         setPagination({ pageNumber, pageSize }); // Update parent pagination state
//         const query = updateSearchParams(filters, sorts, { pageNumber, pageSize });
//         fetchData(query); // Fetch data when pagination changes
//     };

//     const handleFilterUpdate = (newFilters, newSortDir) => {
//         setFilters(newFilters); // Update parent filter state
//         setSorts(newSortDir); // Update parent sort state
//         const query = updateSearchParams(newFilters, newSortDir, { pageNumber: 1, pageSize: paginationState.pageSize });
//         fetchData(query); // Fetch data when filters update
//     };

//     const updateSearchParams = (filters, sorts, pagination) => {
//         const params = new URLSearchParams();
    
//         // Add filters
//         Object.entries(filters).forEach(([key, value]) => {
//             if (value) params.set(key, value);
//         });
    
//         // Add sortDir if provided
//         if (sorts) {
//             params.set("sortDir", sorts);
//         }
    
//         // Add pagination
//         params.set("pageNumber", pagination.pageNumber);
//         params.set("pageSize", pagination.pageSize);
    
//         // Update the URL
//         const queryString = `?${params.toString()}`;
//         window.history.replaceState(null, "", queryString);
//         return queryString;
//     };

//     return {
//         paginationState,
//         handlePaginationChange,
//         handleFilterUpdate,
//     };
// };

