import React, { useState } from "react";
import { useEffect } from "react";
import settings from "../../../settings/api";
import fetchData from "../../../Helpers/apiCalls";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { DataTable } from "../../../components/DataTable/DataTable";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { CalendarDots as CalendarDotsIcon } from "@phosphor-icons/react/dist/ssr/CalendarDots";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { TrendDown as TrendDownIcon } from "@phosphor-icons/react/dist/ssr/TrendDown";
import { TrendUp as TrendUpIcon } from "@phosphor-icons/react/dist/ssr/TrendUp";
import dayjs from "dayjs";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import * as P from "@phosphor-icons/react";
import DownloadIcon from "@mui/icons-material/Download";

function LinearProgressWithLabel(props) {
    return (
        <Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }} display={"inline"}>
                    {props.daysleft !== undefined && props.daysleft + " days left"}
                    {props.daysbeforestart !== undefined && "Start in " + props.daysbeforestart + " days"}
                </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
                <LinearProgress
                    variant="determinate"
                    sx={{
                        borderRadius: 5,
                        height: 6,
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: props.statuscolor, // Bar color
                        },
                        backgroundColor: alpha(props.statuscolor, 0.3), // Track color
                    }}
                    value={props.value}
                    {...props}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="body2" sx={{ color: "text.secondary" }} display={"inline"}>
                    {dayjs(props.startdate).format("DD-MM-YYYY")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} display={"inline"}>
                    {dayjs(props.enddate).format("DD-MM-YYYY")}
                </Typography>
            </Box>
        </Box>
    );
}

export default function TPO() {
    const { id } = useParams();
    const location = useLocation();
    const [schedules, setSchedules] = useState();
    const navigate = useNavigate();
    const [openEditScheduleWarning, setOpenEditScheduleWarning] = useState(false);
    const [selectedBookingForEdit, setSelectedBookingForEdit] = useState({});

    const columns = [
        {
            formatter: (row) => {
                const PIcon = P[row.status.icon];
                return (
                    <Chip
                        // label={label}
                        label={row.status.label}
                        // sx={{ bgcolor: color, color: "#FFF", borderColor: color }}
                        sx={{ bgcolor: row.status.color, color: "#FFF", borderColor: row.status.color }}
                        size="small"
                        variant="outlined"
                        icon={<PIcon color={"#FFF"} weight="fill" />}
                    />
                );
            },
            name: "Status",
            width: "80px",
            align: "center",
        },
        { field: "productName", name: "Product Type", width: "80px" },
        { field: "title", name: "Title", width: "120px" },
        { field: "description", name: "Description", width: "200px" },
        {
            formatter(row) {
                const start = dayjs(row.startDate);
                const end = dayjs(row.endDate);
                const totalDays = end.diff(start, "d") + 1;
                const today = dayjs();
                const daysPassed = today.diff(start, "d");
                const progress = today > end ? 100 : (daysPassed / totalDays) * 100;
                // console.log(progress);
                let daysLeft;
                let daysBeforeStart;
                if (progress < 0) {
                    daysBeforeStart = start.diff(today, "d");
                } else {
                    daysLeft = today.isBefore(end) ? end.diff(today, "d") : 0;
                }
                // console.log(daysLeft, daysBeforeStart);

                return (
                    <LinearProgressWithLabel
                        value={progress}
                        daysleft={daysLeft}
                        daysbeforestart={daysBeforeStart}
                        startdate={row.startDate}
                        enddate={row.endDate}
                        status={row.status}
                        statuscolor={row.status.color}
                    />
                );
            },
            name: "Start & End Date",
            width: "180px",
        },
        { field: "scheduleTypeName", name: "Schedule", width: "100px", align: "center" },
        { field: "previouslyPurchased", name: "Previous Purchased", width: "120px", align: "center" },
        { field: "previouslyBooked", name: "Previous Booked", width: "120px", align: "center" },
        { field: "booking.currentUnitsBooked", name: "Current Units Booked", width: "120px", align: "center" },
        {
            formatter: (row) => {
                const PIcon = P[row.bookingStatus.icon];
                return (
                    <Chip
                        label={row.bookingStatus.label}
                        sx={{ bgcolor: row.bookingStatus.color, color: "#FFF", borderColor: row.bookingStatus.color }}
                        size="small"
                        variant="outlined"
                        icon={<PIcon color={"#FFF"} weight="fill" />}
                    />
                );
            },
            name: "Booking Status",
            width: "80px",
            align: "center",
        },
        {
            formatter: (row) => {
                const addLink = row?.links.find((link) => link.rel === "add");
                return (
                    <>
                        {/* {!!addLink && (
                            <Tooltip title={row.editLabel} placement="top">
                                <IconButton component={RouterLink} to={`/admin/sites/${id}/tpo/booking`}>
                                    <PlusIcon />
                                </IconButton>
                            </Tooltip>
                        )} */}
                        {row.booking === null ? (
                            <Tooltip title={row.editLabel} placement="top">
                                <IconButton
                                    // component={RouterLink} to={`/admin/sites/${id}/tpo/booking`}
                                    onClick={() => createBooking(settings.api().host + addLink.href, addLink.method)}

                                    // onClick={() => createBooking()}
                                >
                                    <PlusIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Edit Schedule" placement="top">
                                <IconButton
                                    // component={RouterLink} to={row.pageUrl}
                                    onClick={() => confirmEditBooking(row)}
                                >
                                    <PencilSimpleIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Download" placement="top">
                            <IconButton
                                disabled={row.bookingStatus.label !== "Complete"}
                                // component={RouterLink} to={row.pageUrl}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            name: "View",
            hideName: true,
            width: "120px",
            align: "center",
        },
    ];
    useEffect(() => {
        fetchTpoSchedules(location.search);

        return () => {};
    }, [location.search]);

    const createBooking = async (url, method) => {
        console.log(url, method);

        const result = await fetchData(url, method);
        // console.log(result, JSON.stringify());

        if (result.success) {
            console.log(result.data);
            navigate(`/admin/sites/${id}/tpo/booking/${result.data.bookingId}`);
        }
    };
    const editBooking = async () => {
        console.log(selectedBookingForEdit);

        const link = selectedBookingForEdit.links.find((l) => l.rel === "self");
        // console.log(settings.api().host + link.href); //+ `?bookingId=${selectedBookingForEdit?.booking?.bookingId}`
        const url = settings.api().host + link.href;
        const result = await fetchData(url, link.method);
        // console.log(result, JSON.stringify());

        if (result.success) {
            console.log(result.data);
            setOpenEditScheduleWarning(false);
            navigate(`/admin/sites/${id}/tpo/booking/${result.data.bookingId}`);
        }
    };
    const confirmEditBooking = async (schedule) => {
        setSelectedBookingForEdit(schedule);
        setOpenEditScheduleWarning(true);
    };

    const fetchTpoSchedules = async (filters = "") => {
        const url = filters !== "" ? settings.api(id).siteTpoSchedules + filters : settings.api(id).siteTpoSchedules;
        const result = await fetchData(url, "GET");
        // console.log(result);

        if (result.success) {
            setSchedules(result.data);
        }
    };

    return (
        <div>
            {/* <h1>TPO Schedules</h1> */}
            <Stack spacing={2}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">WINTER TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">OPEN NOW</Typography>
                                <Typography variant="h2" fontWeight={500} color="success.main">
                                    6
                                </Typography>
                                <Typography variant="subtitle1">Days left</Typography>
                            </CardContent>
                            <Divider />
                            <Box sx={{ p: "16px" }}>
                                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                                    <Box
                                        sx={{
                                            alignItems: "center",
                                            color: true ? "success.main" : "error.main",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {true ? (
                                            <TrendUpIcon fontSize="1.25rem" />
                                        ) : (
                                            <TrendDownIcon fontSize="1.25rem" />
                                        )}
                                    </Box>
                                    <Typography color="text.secondary" variant="body2">
                                        <Typography
                                            color={true ? "success.main" : "error.main"}
                                            component="span"
                                            variant="subtitle2"
                                        >
                                            {new Intl.NumberFormat("en-US", {
                                                style: "percent",
                                                maximumFractionDigits: 2,
                                            }).format(16 / 100)}
                                        </Typography>{" "}
                                        {true ? "increase" : "decrease"} vs last year
                                    </Typography>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">SPRING TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">COMING SOON</Typography>
                                <Typography variant="h2" fontWeight={500} color="success.main">
                                    30
                                </Typography>
                                <Typography variant="subtitle1">Days to booking start</Typography>
                            </CardContent>
                            <Divider />
                            <Box sx={{ p: "16px" }}>
                                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                                    <Typography color="text.secondary" variant="body2">
                                        <Typography
                                            color={true ? "success.main" : "error.main"}
                                            component="span"
                                            variant="subtitle2"
                                        >
                                            2 weeks
                                        </Typography>{" "}
                                        earlier vs last year
                                    </Typography>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={6}>
                        <Card sx={{ height: "242.775px" }}>
                            <CardContent
                            // sx={{height: '156px'}}
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box sx={{ pl: 1 }}>
                    <Card>
                        <CardHeader
                            title={"Booking Schedules"}
                            avatar={
                                <Avatar sx={{ bgcolor: "primary.main" }}>
                                    <CalendarDotsIcon fontSize="x-large" />
                                </Avatar>
                            }
                        />
                        {(schedules?.filteringOptions.length > 0 || schedules?.sortingOptions.length > 0) && (
                            <>
                                <DataTableFilters
                                    filters={schedules?.filteringOptions}
                                    sorts={schedules?.sortingOptions}
                                />
                                <Divider />
                            </>
                        )}
                        {schedules?.items.length > 0 ? (
                            <DataTable columns={columns} rows={schedules?.items} />
                        ) : (
                            <Box sx={{ p: 3 }}>
                                <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                    No records found
                                </Typography>
                            </Box>
                        )}
                        <DataTablePagination
                            count={schedules?.totalItems}
                            pageSize={schedules?.pageSize}
                            pageNumber={schedules?.pageNumber}
                        />
                    </Card>
                </Box>
            </Stack>
            <Dialog
                open={openEditScheduleWarning}
                onClose={() => setOpenEditScheduleWarning(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Edit Schedule Booking</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Continuing this action with update this Booking Status to "Incompete", and a resubmission will
                        be required to complete it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditScheduleWarning(false)}>Cancel</Button>
                    <Button onClick={() => editBooking()} variant="contained">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
