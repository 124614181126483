import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardContent, CardHeader, Tab, Tabs, Typography } from "@mui/material";
import { Calendar as CalendarIcon } from "@phosphor-icons/react";
import CustomTabPanel from "../../../../components/CustomTabPanel";
import Activity from "./Activity";
// import AddEditTPOSchedule from "../AddEditTPOSchedule";
import AddEditTPOScheduleForm from "../../../../components/AddEditTPOScheduleForm";
import TPOScheduleBookings from "./Bookings";
import TPOData from "./Data";
import fetchData from "../../../../Helpers/apiCalls";
import settings from "../../../../settings/api";
import { useParams } from "react-router-dom";

const TPOSchedule = () => {
    const { id } = useParams();
    const [schedule, setSchedule] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        fetchSchedule();

        return () => {};
    }, []);

    const fetchSchedule = async () => {
        const result = await fetchData(settings.api().tpoSchedules + `/${id}`);
        // console.log(result);

        if (result.success) {
            setSchedule(result.data);
        }
    };

    // console.log(schedule);

    return (
        <div>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <CalendarIcon />
                        </Avatar>
                    }
                    title={<Typography variant="h6">{schedule?.title}</Typography>}
                    subheader={
                        <>
                            <Typography variant="subtitle2">{schedule?.organization}</Typography>
                            <Typography variant="body2">{schedule?.productType}, {schedule?.season}</Typography>
                            {/* <Typography variant="body2">{schedule?.season}</Typography> */}
                        </>
                    }
                />
                <CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="featured category tabs">
                            <Tab label="Bookings" />
                            <Tab label="Edit" />
                            <Tab label="Data" />
                            <Tab label="Email" />
                            <Tab label="Activity" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <TPOScheduleBookings />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <AddEditTPOScheduleForm mode={"edit"} schedule={schedule} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                        <TPOData />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={3}></CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={4}>
                        <Activity />
                    </CustomTabPanel>
                </CardContent>
            </Card>
        </div>
    );
};

export default TPOSchedule;
