import React, { useEffect, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, MenuItem, Select, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
import { Truck as TruckIcon } from "@phosphor-icons/react/dist/ssr/Truck";

const rows = [
    {
        partNo: "137862",
        brand: "Michelin",
        productName: "X-Ice",
        size: "225/60R17",
        loadSpeed: "99H",
        type: "W",
        bookedPrice: 125.8,
        totalQty: 70,
        distributor: "",
    },
    {
        partNo: "37846",
        brand: "Michelin",
        productName: "Latitude X-Ice",
        size: "235/65R17",
        loadSpeed: "108T",
        type: "W",
        bookedPrice: 140.33,
        totalQty: 85,
        distributor: "",
    },
];

export default function SelectedDistributors() {
    const [products, setProducts] = useState([]);

    const columns = [
        { field: "partNo", name: "Part #", width: "60px" },
        { field: "brand", name: "Brand", width: "60px" },
        { field: "productName", name: "Product Name", width: "100px" },
        { field: "size", name: "Size", width: "60px" },
        { field: "loadSpeed", name: "Load Speed", width: "70px" },
        { field: "type", name: "Type", width: "40px" },
        {
            formatter: (row) => <Typography>${row.bookedPrice.toFixed(2)}</Typography>,
            name: "Booked Price",
            width: "50px",
        },
        { field: "totalQty", name: "Total Qty.", width: "60px" },
        {
            formatter: (row) => (
                <Select
                    size="small"
                    value={row.distributor}
                    placeholder="-- Select Distributor --"
                    fullWidth
                    sx={{ maxWidth: "120px" }}
                    onChange={(e) => handleDistributorChange(row.partNo, e.target.value)}
                >
                    <MenuItem disabled value="">
                        -- Select Distributor --
                    </MenuItem>
                    <MenuItem value={"Oshawa"}>Oshawa Location</MenuItem>
                    <MenuItem value={"Mississauga"}>Mississauga Location</MenuItem>
                </Select>
            ),
            name: "Select Distributor",
            align: "center",
            width: "120px",
        },
    ];

    useEffect(() => {
        setProducts(rows);

        return () => {};
    }, []);

    const handleDistributorChange = (partNo, value) => {
        const result = products.map((t) =>
            t.partNo === partNo
                ? {
                      ...t,
                      distributor: value,
                  }
                : t
        );

        setProducts(result);
    };

    return (
        <Card><CardHeader
        title={<Typography variant="h6">Select Distributors</Typography>}
        avatar={<Avatar><TruckIcon size={24} /></Avatar>}
    />
            <CardContent>
                <DataTable dense={true} columns={columns} rows={products} />
                <DataTablePagination count={2} pageNumber={1} pageSize={25} />
            </CardContent>
        </Card>
    );
}
