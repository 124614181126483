const formatdate = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    let year = d.getFullYear();
    return `${months[month]} ${day}, ${year}`;
};

export default formatdate;

export const formatdatetime = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    const time = d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    return `${months[month]} ${day}, ${year}, ${time}`;
};
