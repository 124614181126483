import React, { useState } from "react";
import { Button, CardActions, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomCard, CustomContainer } from "./AccountActivation";
import CopyRight from "../../components/CopyRight";

export default function UserAuthNotification() {
    const location = useLocation();
    const navigate = useNavigate();
    const { title, description } = location.state || {};
    const [logo] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme?.logoSmallUri);

    // console.log(location.state);

    return (
        <CustomContainer>
            <CustomCard>
                <img src={logo} alt="Logo" style={{ width: "80px", display: "block", margin: "0 auto" }} />
                {!!location.state ? (
                    <CardContent sx={{ width: "500px" }}>
                        {!!title && (
                            <Typography variant="h5" sx={{ width: "100%", textAlign: "center" }} gutterBottom>
                                {title}
                            </Typography>
                        )}
                        {!!description && (
                            <Typography variant="body1" sx={{ width: "100%", textAlign: "center" }}>
                                {description}
                            </Typography>
                        )}
                    </CardContent>
                ) : (
                    <>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                            Error: Please login again and do not refresh the page.
                        </Typography>
                        <CardActions sx={{ justifyContent: "center" }}>
                            <Button onClick={() => navigate("/login")} variant="contained">
                                Login
                            </Button>
                        </CardActions>
                    </>
                )}
            </CustomCard>
            <CopyRight />
        </CustomContainer>
    );
}
