import React, { useState } from "react";
import {
    Button,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import CopyRight from "../../components/CopyRight";
import { CustomCard, CustomContainer } from "./AccountActivation";
import fetchData from "../../Helpers/apiCalls";
import settings from "../../settings/api";

export default function UserAuthEmailStatus() {
    const location = useLocation();
    const { title, description } = location.state || {};
    const [logo] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme?.logoSmallUri);

    const handleResendValidation = () => {
        const result = fetchData(settings.api().resendValidation + `${location.search}`, "POST");
        if (result.success) {
            console.log(result)
        }
    }

    return (
        <CustomContainer>
            <CustomCard>
                <img src={logo} alt="Logo" style={{ width: "80px", display: "block", margin: "0 auto" }} />
                <CardContent sx={{ width: "500px" }}>
                    {!!title && (
                        <Typography variant="h5" sx={{ width: "100%", textAlign: "center" }} gutterBottom>
                            {title}
                        </Typography>
                    )}
                    {!!description && (
                        <Typography variant="body1" sx={{ width: "100%", textAlign: "center" }}>
                            {description}
                        </Typography>
                    )}
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                        onClick={handleResendValidation}
                        variant="contained"
                    >
                        Resend Validation
                    </Button>
                </CardActions>
            </CustomCard>
            <CopyRight />
        </CustomContainer>
    );
}
