import React, { useEffect, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import fetchData from "../../../Helpers/apiCalls";
import settings from "../../../settings/api";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { updateMenuType } from "../../../settings/redux/componentsActions";
import { connect } from "react-redux";

function OrgAdminSites(props) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [sites, setSites] = useState({});

    const columns = [
        { field: "code", name: "Code", width: "80px" },
        { field: "siteName", name: "Site Name", width: "150px" },
        { field: "siteType", name: "Site Type", width: "80px" },
        { field: "city", name: "City", width: "60px" },
        // { field: "region", name: "Province", width: "50px" },
        { field: "province", name: "Province", width: "50px" },
        { field: "country", name: "Country", width: "60px" },
        {
            formatter: (row) => {
                const link = row.links.find((l) => l.rel === "self").href;
                return (
                    <Stack direction="row">
                        <Tooltip placement="top" title="Manage Site">
                            <IconButton
                                onClick={() => {
                                    props.updateMenuType("sitesAdmin");
                                    localStorage.setItem("menuType", "sitesAdmin");
                                    navigate(row.pageUrl);
                                }}
                            >
                                <GearIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View Site">
                            <IconButton
                                onClick={() => {
                                    navigate(link);
                                }}
                            >
                                <EyeIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
                // }
            },
            name: "Actions",
            hideName: true,
            width: "100px",
            align: "center",
        },
    ];

    useEffect(() => {
        fetchSites(location.search);

        return () => {};
    }, [location.search]);

    const fetchSites = async (filters = "") => {
        const url =
            filters !== ""
                ? settings.api(params.id).organizationSites + filters
                : settings.api(params.id).organizationSites;

        const result = await fetchData(url, "GET");
        if (result.success) {
            setSites(result.data);
        }
    };

    // useEffect(() => {
    //     const fetchGlobalOrganization = async () => {
    //         const result = await fetchData(settings.api(params.id).organizationSites, "GET");
    //         console.log(result);

    //         setSites(result);
    //     };

    //     fetchGlobalOrganization();

    //     return () => {};
    // }, []);

    // console.log(sites?.totalItems, sites?.pageNumber, sites?.pageSize);

    return (
        <div>
            {/* <h1>Organization Site List</h1>
            <p>
                The site list displays all sites (dealers) that an admin has permissions to. This can include all sites
                in an organization or a subset of sites for users such as regional managers. Portal admins can edit
                sites and site users. They can also open a site in the Site Admin View to have full access of that
                site's settings without the need of logging into a site directly.
            </p> */}
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <Icon>corporate_fare</Icon>
                        </Avatar>
                    }
                    title={<Typography variant="h6">Sites</Typography>}
                />

                <CardContent>
                    {(sites?.filteringOptions || sites?.sortingOptions) && (
                        <DataTableFilters
                            filters={sites?.filteringOptions}
                            sorts={sites?.sortingOptions}
                            initialSortDir={sites?.sortingOptions[0]?.field}
                        />
                    )}
                    {sites?.items?.length > 0 ? (
                        <DataTable columns={columns} rows={sites?.items} />
                    ) : (
                        <Typography sx={{ textAlign: "center" }}>This organization does not have sites.</Typography>
                    )}

                    <DataTablePagination
                        count={sites?.totalItems}
                        pageNumber={sites?.pageNumber}
                        pageSize={sites?.pageSize}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMenuType: (menuType) => {
            dispatch(updateMenuType(menuType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgAdminSites);
